import { createSlice, createAction } from "@reduxjs/toolkit";

const initialState = {
  isRecording: false,
  isProcessing: false,
  selectedNotes: [],
  isSidebarOpen: false,
  additionalNoteText: "",
};

const uiSlice = createSlice({
  name: "ui",
  initialState,
  reducers: {
    setIsRecording: (state, action) => {
      state.isRecording = action.payload;
    },
    setIsProcessing: (state, action) => {
      state.isProcessing = action.payload;
    },
    toggleSelectedNote: (state, action) => {
      const index = state.selectedNotes.indexOf(action.payload);
      if (index === -1) {
        state.selectedNotes.push(action.payload);
      } else {
        state.selectedNotes.splice(index, 1);
      }
    },
    clearSelectedNotes: (state) => {
      state.selectedNotes = [];
    },
    toggleSidebar: (state) => {
      state.isSidebarOpen = !state.isSidebarOpen;
    },
    setAdditionalNoteText: (state, action) => {
      state.additionalNoteText = action.payload;
    },
  },
});

export const {
  setIsRecording,
  setIsProcessing,
  toggleSelectedNote,
  clearSelectedNotes,
  toggleSidebar,
  setAdditionalNoteText,
} = uiSlice.actions;

export const clearSelectedSummaries = createAction('ui/clearSelectedSummaries');

export default uiSlice.reducer;
