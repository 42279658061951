import { apiClient, API_ENDPOINTS } from "../../api/apiConfig";
import { clearSelectedNotes, toggleSelectedNote } from "../../store/slices/uiSlice";
import { fetchNotes } from "../../store/slices/notesSlice";
import { filterNotes, filterSummaries } from "./filterUtils";

export const handleNotesFilterChange = ({
    notes,
    filters,
    dispatch,
    clearSelectedSummaries,
    setExpandedNoteId,
    setExpandedSummaryId,
    setFilteredNotes
}) => {
    dispatch(clearSelectedNotes());
    dispatch(clearSelectedSummaries());
    setExpandedNoteId(null);
    setExpandedSummaryId(null);

    const filtered = filterNotes(notes, filters);
    setFilteredNotes(filtered);
};

export const handleSummaryFilterChange = ({
    filters,
    summarizedNotes,
    setSelectedSummaries,
    setExpandedSummaryId,
    setFilteredSummaries
}) => {
    if (setSelectedSummaries) {
        setSelectedSummaries([]);
    }
    if (setExpandedSummaryId) {
        setExpandedSummaryId(null);
    }

    const filtered = filterSummaries(summarizedNotes, filters);
    setFilteredSummaries(filtered);
};

export const handleSummarize = async ({
    selectedNotes,
    selectedPredefinedPrompts,
    customPrompt,
    setIsSummarizing,
    fetchSummarizedNotesHandler,
    dispatch,
    setShowSummarizeModal,
    setCustomPrompt,
    setSelectedPredefinedPrompts,
    setActiveTab
}) => {
    if (selectedNotes.length === 0) return;

    setIsSummarizing(true);
    try {
        const queryParams = new URLSearchParams({
            custom_prompt: selectedPredefinedPrompts.length > 0
                ? selectedPredefinedPrompts[0].systemPrompt
                : customPrompt || "undefined",
            custom_prompt_name: selectedPredefinedPrompts.length > 0
                ? selectedPredefinedPrompts[0].title
                : customPrompt || "undefined"
        }).toString();

        const response = await apiClient.post(
            `${API_ENDPOINTS.SUMMARIZE_NOTES}?${queryParams}`,
            selectedNotes.map(String)
        );
        if (response) {
            await fetchSummarizedNotesHandler();
            dispatch(clearSelectedNotes());
            setShowSummarizeModal(false);
            setCustomPrompt("");
            setSelectedPredefinedPrompts([]);
            setActiveTab(1);
        }
    } catch (error) {
        console.error("Failed to summarize notes:", error);
    } finally {
        setIsSummarizing(false);
    }
};

export const handleCopy = async (text, setCopied) => {
    try {
        await navigator.clipboard.writeText(text);
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    } catch (err) {
        console.error("Failed to copy text:", err);
    }
};

export const handleDeleteNote = async ({
    recordId,
    setDeletingNoteId,
    dispatch,
    fetchSummarizedNotesHandler
}) => {
    if (window.confirm("Are you sure you want to delete this record?")) {
        setDeletingNoteId(recordId);

        try {
            await apiClient.delete(API_ENDPOINTS.DELETE_RECORD, {
                data: { record_ids: [recordId] },
            });
            dispatch(fetchNotes());
            await fetchSummarizedNotesHandler();
        } catch (error) {
            console.error("Failed to delete note:", error);
        } finally {
            setDeletingNoteId(null);
        }
    }
};

export const handleUpdateSummary = async ({
    recordId,
    editedSummaryText,
    setUpdatingSummaryId,
    setEditingSummary,
    setEditedSummaryText,
    setFilteredNotes
}) => {
    try {
        setUpdatingSummaryId(recordId);
        await apiClient.put(API_ENDPOINTS.UPDATE_RECORD(recordId), {
            summary: editedSummaryText,
        });

        setFilteredNotes(prevNotes =>
            prevNotes.map(note =>
                note.id === recordId
                    ? { ...note, summary: editedSummaryText }
                    : note
            )
        );

        setEditingSummary(null);
        setEditedSummaryText("");
    } catch (error) {
        console.error("Failed to update summary:", error);
    } finally {
        setUpdatingSummaryId(null);
    }
};

export const handleBulkDeleteNote = async ({
    selectedNotes,
    setIsBulkDeleting,
    dispatch,
    fetchSummarizedNotesHandler
}) => {
    if (selectedNotes.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedNotes.length} record(s)?`)) {
        setIsBulkDeleting(true);
        try {
            await apiClient.delete(API_ENDPOINTS.DELETE_RECORD, {
                data: { record_ids: selectedNotes },
            });
            dispatch(clearSelectedNotes());
            dispatch(fetchNotes());
            await fetchSummarizedNotesHandler();
        } catch (error) {
            console.error("Failed to delete notes:", error);
        } finally {
            setIsBulkDeleting(false);
        }
    }
};

export const handleSelectAllNotes = ({
    checked,
    filteredNotes,
    dispatch
}) => {
    if (checked) {
        const filteredNoteIds = filteredNotes.map(note => note.id);
        dispatch(clearSelectedNotes());
        filteredNoteIds.forEach(id => {
            dispatch(toggleSelectedNote(id));
        });
    } else {
        dispatch(clearSelectedNotes());
    }
};

export const handleDeleteSummaries = async ({
    selectedSummaries,
    setIsBulkDeletingSummaries,
    setSelectedSummaries,
    setDeletingSummaryId,
    dispatch,
    fetchSummarizedNotesHandler
}) => {
    if (selectedSummaries.length === 0) return;

    if (window.confirm(`Are you sure you want to delete ${selectedSummaries.length} summary record(s)?`)) {
        setIsBulkDeletingSummaries(true);
        try {
            await apiClient.delete(API_ENDPOINTS.DELETE_CUSTOM_SUMMARY, {
                data: { custom_summary_ids: selectedSummaries },
            });
            setSelectedSummaries([]);
            setDeletingSummaryId(null);
            dispatch(fetchNotes());
            await fetchSummarizedNotesHandler();
        } catch (error) {
            console.error("Failed to delete summaries:", error);
            setDeletingSummaryId(null);
        } finally {
            setIsBulkDeletingSummaries(false);
        }
    } else {
        setDeletingSummaryId(null);
    }
};

export const handleSelectAllNotesSummaries = ({
    checked,
    filteredSummaries,
    setSelectedSummaries
}) => {
    if (checked) {
        const filteredSummaryIds = filteredSummaries.map(summary => summary.id);
        setSelectedSummaries(filteredSummaryIds);
    } else {
        setSelectedSummaries([]);
    }
};

export const handleSummarySelection = ({
    summaryId,
    setSelectedSummaries
}) => {
    setSelectedSummaries((prev) => {
        if (prev.includes(summaryId)) {
            return prev.filter((id) => id !== summaryId);
        } else {
            return [...prev, summaryId];
        }
    });
};

export const handleSummaryRowClick = ({
    summaryId,
    expandedSummaryId,
    setExpandedSummaryId
}) => {
    setExpandedSummaryId(expandedSummaryId === summaryId ? null : summaryId);
};

export const handleUpdateCustomSummary = async ({
    summaryId,
    editedGeneratedSummaryText,
    setUpdatingGeneratedSummaryId,
    setEditingGeneratedSummary,
    setEditedGeneratedSummaryText,
    setFilteredSummaries
}) => {
    try {
        setUpdatingGeneratedSummaryId(summaryId);
        await apiClient.put(API_ENDPOINTS.UPDATE_CUSTOM_SUMMARY(summaryId), {
            custom_summary: editedGeneratedSummaryText,
        });

        setFilteredSummaries(prevSummaries =>
            prevSummaries.map(summary =>
                summary.id === summaryId
                    ? { ...summary, summary: editedGeneratedSummaryText }
                    : summary
            )
        );

        setEditingGeneratedSummary(null);
        setEditedGeneratedSummaryText("");
    } catch (error) {
        console.error("Failed to update generated summary:", error);
    } finally {
        setUpdatingGeneratedSummaryId(null);
    }
};

export const handleRegenerateSummary = async ({
    recordId,
    setRegeneratingSummaryId,
    setNoteDetails,
    setFilteredNotes,
    notes
}) => {
    try {
        setRegeneratingSummaryId(recordId);
        const response = await apiClient.post(API_ENDPOINTS.REGENERATE_DEFAULT_SUMMARY(recordId));

        if (setNoteDetails) {
            setNoteDetails(prev => ({
                ...prev,
                summary: response?.response
            }));
        }

        setFilteredNotes(prevNotes => {
            const notesToUpdate = prevNotes || notes?.records || [];
            return notesToUpdate.map(note =>
                note.id === recordId
                    ? { ...note, summary: response?.response }
                    : note
            );
        });

        return response?.response;
    } catch (error) {
        console.error("Failed to regenerate summary:", error);
        throw error;
    } finally {
        setRegeneratingSummaryId(null);
    }
};
