export const formatDateTime = (dateString) => {
  return new Date(dateString)?.toLocaleString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
    hour12: false
  });
};

export const getFormattedDate = (date = new Date()) => {
  const targetDate = new Date(date);
  return targetDate.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric'
  });
};

export const formatRelativeDateTime = (dateString) => {
  const date = new Date(dateString);
  const today = new Date();
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);

  if (date.toDateString() === today.toDateString()) {
    return "Today's Summary";
  } else if (date.toDateString() === yesterday.toDateString()) {
    return "Yesterday's Summary";
  } else {
    return date.toLocaleDateString('en-GB', {
      day: 'numeric',
      month: 'long',
      year: 'numeric'
    });
  }
};

export const TOAST_OPTIONS = {
  className: '',
  style: {
    padding: '16px',
    color: '#1F2937',
    fontSize: '14px',
    maxWidth: '500px',
    backgroundColor: '#FFFFFF',
    border: '1px solid #E5E7EB',
    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)',
  },
  success: {
    style: {
      backgroundColor: '#F0FDF4',
      border: '1px solid #BBF7D0',
      color: '#166534',
    },
    iconTheme: {
      primary: '#22C55E',
      secondary: '#FFFFFF',
    },
  },
  error: {
    style: {
      backgroundColor: '#FEF2F2',
      border: '1px solid #FECACA',
      color: '#991B1B',
    },
    iconTheme: {
      primary: '#EF4444',
      secondary: '#FFFFFF',
    },
  },
  duration: 4000,
};

export const formatShiftDate = (date) => {
  return date.toLocaleDateString('en-GB', {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
  });
};

export const getPastDaysDate = (daysAgo) => {
  const date = new Date();
  date.setDate(date.getDate() - daysAgo);
  return date;
};

export const getPast5Days = () => {
  return Array.from({ length: 5 }, (_, i) => getPastDaysDate(i));
};

export const formatShortDate = (dateString) => {
  const date = new Date(dateString);
  return date.toLocaleDateString('en-GB', {
    day: '2-digit',
    month: '2-digit',
    year: '2-digit'
  });
}; 