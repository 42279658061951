import React, { useEffect, useRef } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { useLocation } from 'react-router-dom';
import { syncPrompts } from "../store/slices/notesSlice";
import AccountSection from '../components/Settings/AccountSection';
import SessionSection from '../components/Settings/SessionSection';
import CustomPromptsSection from '../components/Settings/CustomPromptsSection';
import CategorySection from '../components/Settings/CategorySection';
import BackendPromptsSection from '../components/Settings/BackendPromptsSection';

export const SettingsSection = ({ title, icon: Icon, children }) => (
    <div className="bg-white rounded-lg shadow p-6 mb-6">
        <div className="flex items-center mb-4">
            <Icon className="h-6 w-6 text-primary-600 mr-2" />
            <h2 className="text-lg font-medium text-gray-900">{title}</h2>
        </div>
        {children}
    </div>
);

const Settings = () => {
    const dispatch = useDispatch();
    const isPromptsLoading = useSelector((state) => state.notes.isPromptsLoading);
    const loading = isPromptsLoading;
    const location = useLocation();
    const categorySectionRef = useRef(null);
    const backendPromptsRef = useRef(null);

    useEffect(() => {
        dispatch(syncPrompts());
    }, [dispatch]);

    useEffect(() => {
        if (location.state?.section === 'categories' && categorySectionRef.current) {
            categorySectionRef.current.scrollIntoView({ behavior: 'smooth' });
        } else if (location.state?.section === 'backend-prompts' && backendPromptsRef.current) {
            backendPromptsRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [location.state]);

    return (
        <div className="w-full mx-auto py-6 px-4">
            <h1 className="text-2xl font-bold text-gray-900 mb-6">Echo Settings</h1>
            {loading ? (
                <div className="flex items-center justify-center h-64">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
                </div>
            ) : (
                <>
                    <AccountSection />
                    <SessionSection />
                    <div ref={categorySectionRef}>
                        <CategorySection />
                    </div>
                    <div ref={backendPromptsRef}>
                        <BackendPromptsSection />
                    </div>
                    <CustomPromptsSection />
                </>
            )}
        </div>
    );
};

export default Settings; 