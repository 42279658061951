import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { Provider } from "react-redux";
import { store } from "./store/store";
import Layout from "./components/Layout";
import Login from "./components/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import { Auth0Provider } from "@auth0/auth0-react";
import { AuthProvider } from "./auth/AuthContext";
import { initHotjar } from "./utils/hotjar";
import HighlightsPage from './pages/HighlightsPage';
import RecordsPage from './pages/RecordsPage';
import NotesViewPage from './pages/NotesViewPage';
import Settings from "./pages/SettingsPage";

function App() {
  useEffect(() => {
    initHotjar();
  }, []);

  const params = new URLSearchParams(window.location.search);
  const invitation = params.get('invitation');
  const organization = params.get('organization');

  return (
    <Auth0Provider
      domain={process.env.REACT_APP_AUTH0_DOMAIN}
      clientId={process.env.REACT_APP_AUTH0_CLIENT_ID}
      redirectUri={process.env.REACT_APP_AUTH0_REDIRECT_URI}
      {...(invitation && { invitation })}
      {...(organization && { organization })}
    >
      <AuthProvider>
        <Provider store={store}>
          <Router>
            <Routes>
              <Route path="/" element={<Login />} />
              <Route
                path="/home"
                element={
                  <Layout>
                    <ProtectedRoute element={<HighlightsPage />} />
                  </Layout>
                }
              />
              <Route
                path="/logo150.png"
                element={<img src="/logo150.png" alt="Logo" />}
              />
              <Route
                path="/notes"
                element={
                  <Layout>
                    <ProtectedRoute element={<NotesViewPage />} />
                  </Layout>
                }
              />
              <Route
                path="/record-page"
                element={
                  <Layout>
                    <ProtectedRoute element={<RecordsPage />} />
                  </Layout>
                }
              />
              <Route
                path="/settings"
                element={
                  <Layout>
                    <ProtectedRoute element={<Settings />} />
                  </Layout>
                }
              />
            </Routes>
          </Router>
        </Provider>
      </AuthProvider>
    </Auth0Provider>
  );
}

export default App;
