import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { fetchNotes, syncPrompts } from "../store/slices/notesSlice";
import {
  toggleSelectedNote,
  clearSelectedNotes,
  clearSelectedSummaries
} from "../store/slices/uiSlice";
import {
  DocumentTextIcon,
  DocumentDuplicateIcon,
  XMarkIcon,
  ClipboardDocumentIcon,
  TrashIcon,
  PencilIcon,
  CheckIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";
import { Tab, RadioGroup } from "@headlessui/react";
import ExpandIcon from "./NotesView/ExpandIcon";
import {
  INITIAL_FILTERS,
  FilterPanel
} from "./NotesView/filterUtils";
import AudioPlayer from "./NotesView/AudioPlayer";
import {
  fetchSummarizedNotes as fetchSummarizedNotesUtil,
  fetchPredefinedPrompts as fetchPredefinedPromptsUtil,
  fetchRecordDetails as fetchRecordDetailsUtil,
  getCategoriesByRecordType
} from "./NotesView/fetchUtils";
import {
  handleNotesFilterChange,
  handleSummarize,
  handleCopy,
  handleDeleteNote,
  handleUpdateSummary,
  handleBulkDeleteNote,
  handleSelectAllNotes,
  handleDeleteSummaries,
  handleSelectAllNotesSummaries,
  handleSummarySelection,
  handleSummaryRowClick,
  handleSummaryFilterChange,
  handleUpdateCustomSummary,
  handleRegenerateSummary
} from "./NotesView/handleUtils";
import { formatDateTime, getFormattedDate, formatRelativeDateTime, formatShortDate } from '../utils/constants';
import { useObservationSummary } from './NotesView/observationSummary';
import { editAINoteSummaryPermission, deleteRecordPermission } from '../utils/permissions';


const ListItemHeader = ({
  isSelected,
  onCheckboxChange,
  title,
  subtitle,
  record_type_format,
  timestamp,
  userName,
  customPromptName,
  backendPromptName,
  onDelete,
  isDeleting,
  isExpanded,
  id,
  category,
  shiftDate,
  recordedDate
}) => (
  <div className="flex items-center justify-between">
    <div className="flex items-center space-x-3">
      <input
        type="checkbox"
        checked={isSelected}
        onChange={(e) => {
          e.stopPropagation();
          onCheckboxChange(e);
        }}
        onClick={(e) => e.stopPropagation()}
        className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
      />
      <div className="flex flex-col space-y-1">
        <div className="flex flex-col sm:flex-row sm:items-center sm:space-x-2">
          <span className="text-sm font-medium text-gray-900">
            {id} - {category?.split('_').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ')}
          </span>
          <span className="text-sm text-gray-600">
            By: {userName || "Not Available"}
          </span>
        </div>
        <div className="flex flex-col sm:flex-row text-xs text-gray-500 space-y-1 sm:space-y-0 sm:space-x-2">
          <span>Shift: {shiftDate}</span>
          <span className="hidden sm:inline">|</span>
          <span>Recorded: {recordedDate}</span>
        </div>
        <div className="text-xs text-gray-500">
          {title.includes('Summary') &&
            `Custom Prompt: ${customPromptName || "Not Available"}`
          }
        </div>
      </div>
      <ExpandIcon isExpanded={isExpanded} />
    </div>
    {deleteRecordPermission() && (
      <button
        onClick={(e) => {
          e.stopPropagation();
          onDelete();
        }}
        disabled={isDeleting}
        className="p-1 hover:bg-red-50 rounded-full disabled:opacity-50 disabled:cursor-not-allowed"
      >
        {isDeleting ? (
          <svg
            className="animate-spin h-5 w-5 text-red-500"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        ) : (
          <TrashIcon className="h-5 w-5 text-red-500 hover:text-red-600" />
        )}
      </button>
    )}
  </div>
);

function NotesView() {
  const dispatch = useDispatch();
  const notes = useSelector((state) => state.notes.notes);
  // Update loading selector to be more specific
  const isNotesLoading = useSelector((state) => state.notes.isNotesLoading);
  const isPromptsLoading = useSelector((state) => state.notes.isPromptsLoading);
  // Use combined loading state
  const loading = isNotesLoading || isPromptsLoading;
  const selectedNotes = useSelector((state) => state.ui.selectedNotes);
  const [customPrompt, setCustomPrompt] = useState("");
  const [showSummarizeModal, setShowSummarizeModal] = useState(false);
  const [expandedNoteId, setExpandedNoteId] = useState(null);
  const [summarizedNotes, setSummarizedNotes] = useState([]);
  const [copiedTranscript, setCopiedTranscript] = useState(false);
  const [copiedAdditionalNotes, setCopiedAdditionalNotes] = useState(false);
  const [copiedSummary, setCopiedSummary] = useState(false);
  const [copiedGeneratedSummary, setCopiedGeneratedSummary] = useState(false);
  const [isSummarizing, setIsSummarizing] = useState(false);
  const [expandedSummaryId, setExpandedSummaryId] = useState(null);
  const [editingSummary, setEditingSummary] = useState(null);
  const [editedSummaryText, setEditedSummaryText] = useState("");
  const [editingGeneratedSummary, setEditingGeneratedSummary] = useState(null);
  const [editedGeneratedSummaryText, setEditedGeneratedSummaryText] = useState("");
  const [predefinedPrompts, setPredefinedPrompts] = useState([]);
  const [selectedPredefinedPrompts, setSelectedPredefinedPrompts] = useState([]);
  const [loadingSummaries, setLoadingSummaries] = useState(false);
  const [loadingPrompts, setLoadingPrompts] = useState(false);
  const [selectedRecordDetails, setSelectedRecordDetails] = useState(null);
  const [loadingRecordDetails, setLoadingRecordDetails] = useState(false);
  const [isBulkDeleting, setIsBulkDeleting] = useState(false);
  const [deletingNoteId, setDeletingNoteId] = useState(null);
  const [selectedSummaries, setSelectedSummaries] = useState([]);
  const [isBulkDeletingSummaries, setIsBulkDeletingSummaries] = useState(false);
  const [deletingSummaryId, setDeletingSummaryId] = useState(null);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [filteredSummaries, setFilteredSummaries] = useState([]);
  const [currentFilters, setCurrentFilters] = useState();
  const [regeneratingSummaryId, setRegeneratingSummaryId] = useState(null);
  const [updatingSummaryId, setUpdatingSummaryId] = useState(null);
  const [updatingGeneratedSummaryId, setUpdatingGeneratedSummaryId] = useState(null);
  const [filters, setFilters] = useState(currentFilters || INITIAL_FILTERS);
  const [summaryFilters, setSummaryFilters] = useState(currentFilters || INITIAL_FILTERS);
  const [expandedObservationId, setExpandedObservationId] = useState(null);
  const categoriesByRecordType = getCategoriesByRecordType(notes);
  const {
    observationSummaries,
    loadingObservations,
    copiedObservations,
    regeneratingObservationId,
    fetchObservationSummaryHandler,
    handleCopyObservation,
    handleRegenerateObservation
  } = useObservationSummary();

  useEffect(() => {
    // Initial setup - runs once on mount
    dispatch(clearSelectedNotes());
    dispatch(clearSelectedSummaries());
    dispatch(fetchNotes());
    dispatch(syncPrompts());
    fetchSummarizedNotesHandler();

    // Get selected child from localStorage
    const selectedChild = localStorage.getItem('selectedChild');
    const childName = selectedChild ? JSON.parse(selectedChild).name : '';

    // Pass the child name to the fetch handler
    fetchObservationSummaryHandler(childName);
  }, []); // Empty dependency array means this runs once on mount

  useEffect(() => {
    // Handle storage changes
    const handleStorageChange = () => {
      const selectedChild = localStorage.getItem('selectedChild');
      if (selectedChild) {
        const childData = JSON.parse(selectedChild);
        // Update both notes and summary filters with the new child
        const newFilters = {
          ...INITIAL_FILTERS,
          entityName: childData.name
        };

        handleNotesFiltersChange(newFilters);
        handleSummaryFiltersChange(newFilters);
      }
    };

    // Listen for storage events
    window.addEventListener('storage', handleStorageChange);

    // Handle initial filters when notes are loaded
    if (!loading && notes?.records) {
      // Get initial filters
      const selectedChild = localStorage.getItem('selectedChild');
      const initialFilters = selectedChild
        ? {
          ...INITIAL_FILTERS,
          entityName: JSON.parse(selectedChild).name
        }
        : INITIAL_FILTERS;

      setCurrentFilters(initialFilters);
      handleNotesFiltersChange(initialFilters);
    }

    // Cleanup storage listener
    return () => {
      window.removeEventListener('storage', handleStorageChange);
    };
  }, [loading, notes]); // Include both dependencies

  useEffect(() => {
    if (summarizedNotes) {
      // Apply initial filter in summaries
      handleSummaryFiltersChange(INITIAL_FILTERS);
    }
  }, [summarizedNotes]);

  useEffect(() => {
    if (showSummarizeModal) {
      fetchPredefinedPromptsHandler();
    }
  }, [showSummarizeModal, notes, selectedNotes]);


  const fetchSummarizedNotesHandler = () => {
    fetchSummarizedNotesUtil(setLoadingSummaries, setSummarizedNotes);
  };

  const fetchPredefinedPromptsHandler = () => {
    fetchPredefinedPromptsUtil(notes, selectedNotes, setLoadingPrompts, setPredefinedPrompts);
  };

  const fetchRecordDetailsHandler = (recordId) => {
    fetchRecordDetailsUtil(notes, recordId, setLoadingRecordDetails, setSelectedRecordDetails);
  };

  const handleNotesFiltersChange = (newFilters) => {
    setFilters(newFilters);
    handleNotesFilterChange({
      notes,
      filters: newFilters,
      dispatch,
      clearSelectedSummaries,
      setExpandedNoteId,
      setExpandedSummaryId,
      setFilteredNotes
    });
  };

  const handleSummaryFiltersChange = (newFilters) => {
    setSummaryFilters(newFilters);
    handleSummaryFilterChange({
      filters: newFilters,
      summarizedNotes,
      setSelectedSummaries,
      setExpandedSummaryId,
      setFilteredSummaries
    });
  };

  const handleSummarizeWrapper = async () => {
    await handleSummarize({
      selectedNotes,
      selectedPredefinedPrompts,
      customPrompt,
      setIsSummarizing,
      fetchSummarizedNotesHandler,
      dispatch,
      setShowSummarizeModal,
      setCustomPrompt,
      setSelectedPredefinedPrompts,
    });
  };

  const handleDeleteNoteWrapper = async (recordId) => {
    await handleDeleteNote({
      recordId,
      setDeletingNoteId,
      dispatch,
      fetchSummarizedNotesHandler
    });
  };

  const handleUpdateSummaryWrapper = async (recordId) => {
    await handleUpdateSummary({
      recordId,
      editedSummaryText,
      setUpdatingSummaryId,
      setEditingSummary,
      setEditedSummaryText,
      setFilteredNotes
    });
  };

  const handleBulkDeleteNoteWrapper = async () => {
    await handleBulkDeleteNote({
      selectedNotes,
      setIsBulkDeleting,
      dispatch,
      fetchSummarizedNotesHandler
    });
  };

  const handleSelectAllNotesWrapper = (checked) => {
    handleSelectAllNotes({
      checked,
      filteredNotes,
      dispatch
    });
  };

  const handleDeleteSummariesWrapper = async () => {
    await handleDeleteSummaries({
      selectedSummaries,
      setIsBulkDeletingSummaries,
      setSelectedSummaries,
      setDeletingSummaryId,
      dispatch,
      fetchSummarizedNotesHandler
    });
  };

  const handleSelectAllNotesSummariesWrapper = (checked) => {
    handleSelectAllNotesSummaries({
      checked,
      filteredSummaries,
      setSelectedSummaries
    });
  };

  const handleSummarySelectionWrapper = (summaryId) => {
    handleSummarySelection({
      summaryId,
      setSelectedSummaries
    });
  };

  const handleSummaryRowClickWrapper = (summaryId) => {
    handleSummaryRowClick({
      summaryId,
      expandedSummaryId,
      setExpandedSummaryId
    });
  };

  const handleUpdateCustomSummaryWrapper = async (summaryId) => {
    await handleUpdateCustomSummary({
      summaryId,
      editedGeneratedSummaryText,
      setUpdatingGeneratedSummaryId,
      setEditingGeneratedSummary,
      setEditedGeneratedSummaryText,
      setFilteredSummaries
    });
  };

  const handleRegenerateSummaryWrapper = async (recordId) => {
    await handleRegenerateSummary({
      recordId,
      setRegeneratingSummaryId,
      setSelectedRecordDetails,
      setFilteredNotes,
      notes
    });
  };

  // Get selected child from localStorage
  const selectedChild = localStorage.getItem('selectedChild');
  const childName = selectedChild ? JSON.parse(selectedChild).name : '';

  // Pass both summaryId and childName to the handler
  //handleRegenerateObservation(summaryId, childName);

  return (
    <div className="space-y-6">
      <Tab.Group
      >
        <Tab.List className="flex space-x-1 rounded-lg bg-blue-900/20 p-1 mb-2">
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-md font-medium leading-5 text-blue-700
               ${selected
                ? "bg-white shadow"
                : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Notes
          </Tab>
          <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-md font-medium leading-5 text-blue-700
               ${selected
                ? "bg-white shadow"
                : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Observations
          </Tab>
          {/* {/* Commenting out Summaries Tab for now - TODO */}
          {/* <Tab
            className={({ selected }) =>
              `w-full rounded-lg py-2.5 text-md font-medium leading-5 text-blue-700
               ${selected
                ? "bg-white shadow"
                : "text-blue-100 hover:bg-white/[0.12] hover:text-white"
              }`
            }
          >
            Summaries
          </Tab> */}
          {/* Hiding Summaries Tab for now - TODO */}
        </Tab.List>
        <Tab.Panels>
          {/* Notes Panel - Move existing Notes panel content here */}
          <Tab.Panel>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
              <h2 className="text-2xl font-bold text-gray-900">Your Notes</h2>
            </div>

            <div>
              <div className="bg-white shadow overflow-hidden sm:rounded-md p-4">
                {!loading && notes?.records && (
                  <FilterPanel
                    filters={filters}
                    onFilterChange={handleNotesFiltersChange}
                    availableCategories={categoriesByRecordType}
                    notes={notes?.records || []}
                  />
                )}

                {/* Loading State */}
                {loading && (
                  <div className="flex justify-center items-center py-12">
                    <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
                  </div>
                )}

                {/* No Notes State */}
                {!loading &&
                  (!notes?.records || notes.records.length === 0) && (
                    <div className="text-center py-12">
                      <p className="text-gray-500">Notes not available</p>
                    </div>
                  )}

                {/* Notes List */}
                {!loading && notes?.records?.length > 0 && filteredNotes.length > 0 && (
                  <>
                    <div className="p-4 border-b flex items-center justify-between bg-gray-50">
                      <div className="flex items-center space-x-4">
                        <input
                          type="checkbox"
                          checked={
                            filteredNotes.length > 0 &&
                            filteredNotes.every((note) =>
                              selectedNotes.includes(note.id)
                            )
                          }
                          onChange={(e) => handleSelectAllNotesWrapper(e.target.checked)}
                          className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                        />
                        <span className="text-sm text-gray-600">
                          {selectedNotes.length} selected
                        </span>
                      </div>
                      {selectedNotes.length > 0 && (
                        <div className="flex space-x-3">
                          {/* Commenting out Summarize button for now - TODO */}
                          {/* <button
                            onClick={() => setShowSummarizeModal(true)}
                            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-primary-600 hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                          >
                            <DocumentDuplicateIcon className="h-4 w-4 mr-1.5" />
                            Summarize ({selectedNotes.length})
                          </button> */}
                          {/* Commenting out Summarize button for now - TODO */}
                          <button
                            onClick={handleBulkDeleteNoteWrapper}
                            disabled={isBulkDeleting}
                            className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-75 disabled:cursor-not-allowed"
                          >
                            {isBulkDeleting ? (
                              <>
                                <svg
                                  className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                                  xmlns="http://www.w3.org/2000/svg"
                                  fill="none"
                                  viewBox="0 0 24 24"
                                >
                                  <circle
                                    className="opacity-25"
                                    cx="12"
                                    cy="12"
                                    r="10"
                                    stroke="currentColor"
                                    strokeWidth="4"
                                  />
                                  <path
                                    className="opacity-75"
                                    fill="currentColor"
                                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                  />
                                </svg>
                                Deleting...
                              </>
                            ) : (
                              <>
                                <TrashIcon className="h-4 w-4 mr-1.5" />
                                Delete
                              </>
                            )}
                          </button>
                        </div>
                      )}
                    </div>

                    {/* Show "Notes not available" if filtered data is empty */}
                    {filteredNotes.length === 0 ? (
                      <div className="text-center py-12">
                        <p className="text-gray-500">Notes not available</p>
                      </div>
                    ) : (
                      <ul className="divide-y-0">
                        {filteredNotes.map((note) => (
                          <li
                            key={note.id}
                            onClick={(e) => {
                              e.stopPropagation();
                              setExpandedNoteId(
                                expandedNoteId === note.id ? null : note.id
                              );
                            }}
                            className={`border-l-4 transition-colors duration-200 cursor-pointer ${expandedNoteId === note.id
                              ? "border-l-primary-600 bg-gray-50"
                              : "border-l-transparent hover:bg-gray-50"
                              } ${selectedNotes.includes(note.id)
                                ? "bg-primary-50"
                                : ""
                              }`}
                          >
                            <div className="px-4 py-4 sm:px-6">
                              <ListItemHeader
                                isSelected={selectedNotes.includes(note.id)}
                                onCheckboxChange={() =>
                                  dispatch(toggleSelectedNote(note.id))
                                }
                                title={`${note.record_type.split("_")[0].charAt(0).toUpperCase() + note.record_type.split("_")[0].slice(1)} Note`}
                                subtitle={`Record ID: ${note.record_id_int}`}
                                record_type_format={note?.record_type_format}
                                // timestamp={(getFormattedDate(note?.shift_date)) + " - " + (note?.shift?.charAt(0).toUpperCase() + note?.shift?.slice(1)) + " Shift"}
                                timestamp={(getFormattedDate(note?.shift_date))}
                                userName={note?.user_name}
                                backendPromptName={note?.backend_prompt_name}
                                customPromptName={null}
                                onDelete={() => handleDeleteNoteWrapper(note.id)}
                                isDeleting={deletingNoteId === note.id}
                                isExpanded={expandedNoteId === note.id}
                                id={note.record_id_int}
                                category={note.category}
                                shiftDate={formatShortDate(note.shift_date)}
                                recordedDate={formatDateTime(note.created_at)}
                              />

                              {/* Preview when not expanded */}
                              {expandedNoteId !== note.id && (
                                <div className="mt-2">
                                  <p className="text-sm text-gray-600 line-clamp-2">
                                    {note.summary || "AI Note Assistant not available"}
                                  </p>
                                </div>
                              )}
                            </div>

                            {/* Expanded View */}
                            {expandedNoteId === note.id && (
                              <div
                                className="mt-4 space-y-4"
                                onClick={(e) => e.stopPropagation()}
                              >
                                <div className="bg-white rounded-lg p-4 shadow-sm">
                                  <div className="px-6 py-5 space-y-4">
                                    {/* Audio Section */}
                                    <div className="bg-white rounded-lg p-4 shadow-sm">
                                      <h4 className="text-sm font-medium text-gray-900 mb-3">
                                        Audio Recording
                                      </h4>
                                      <AudioPlayer
                                        recordId={note.id}
                                        record_type_format={note.record_type_format}
                                      />
                                    </div>

                                    {/* AI Note Assistant Section */}
                                    <div className="bg-white rounded-lg p-4 shadow-sm">
                                      <div className="flex justify-between items-center mb-3">
                                        <h4 className="text-sm font-medium text-gray-900">
                                          AI Note Assistant
                                        </h4>
                                        <div className="flex items-center space-x-2">
                                          {editingSummary === note.id ? (
                                            <>
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleUpdateSummaryWrapper(note.id);
                                                }}
                                                className="inline-flex items-center text-sm text-green-600 hover:text-green-700 px-2 py-1 rounded-md hover:bg-green-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                              >
                                                {updatingSummaryId === note.id ? (
                                                  <>
                                                    <svg
                                                      className="animate-spin h-4 w-4 mr-1"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      fill="none"
                                                      viewBox="0 0 24 24"
                                                    >
                                                      <circle
                                                        className="opacity-25"
                                                        cx="12"
                                                        cy="12"
                                                        r="10"
                                                        stroke="currentColor"
                                                        strokeWidth="4"
                                                      />
                                                      <path
                                                        className="opacity-75"
                                                        fill="currentColor"
                                                        d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                      />
                                                    </svg>
                                                    Saving...
                                                  </>
                                                ) : (
                                                  <>
                                                    <CheckIcon className="h-4 w-4 mr-1" />
                                                    Save
                                                  </>
                                                )}
                                              </button>
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setEditingSummary(null);
                                                  setEditedSummaryText("");
                                                }}
                                                className="inline-flex items-center text-sm text-red-600 hover:text-red-700 px-2 py-1 rounded-md hover:bg-red-50"
                                              >
                                                <XMarkIcon className="h-4 w-4 mr-1" />
                                                Cancel
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              {!note.summary && (
                                                <button
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleRegenerateSummaryWrapper(note.id);
                                                  }}
                                                  disabled={regeneratingSummaryId === note.id}
                                                  className="inline-flex items-center text-sm text-primary-600 hover:text-primary-700 px-2 py-1 rounded-md hover:bg-primary-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                                >
                                                  {regeneratingSummaryId === note.id ? (
                                                    <>
                                                      <svg
                                                        className="animate-spin h-4 w-4 mr-1"
                                                        xmlns="http://www.w3.org/2000/svg"
                                                        fill="none"
                                                        viewBox="0 0 24 24"
                                                      >
                                                        <circle
                                                          className="opacity-25"
                                                          cx="12"
                                                          cy="12"
                                                          r="10"
                                                          stroke="currentColor"
                                                          strokeWidth="4"
                                                        />
                                                        <path
                                                          className="opacity-75"
                                                          fill="currentColor"
                                                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                        />
                                                      </svg>
                                                      Regenerating...
                                                    </>
                                                  ) : (
                                                    <>
                                                      <ArrowPathIcon className="h-4 w-4 mr-1" />
                                                      Regenerate
                                                    </>
                                                  )}
                                                </button>
                                              )}
                                              {editAINoteSummaryPermission() && (
                                                <button
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    setEditingSummary(note.id);
                                                    setEditedSummaryText(note.summary || "");
                                                  }}
                                                  className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                                >
                                                  <PencilIcon className="h-4 w-4 mr-1" />
                                                  Edit
                                                </button>
                                              )}
                                              {note.summary && (
                                                <button
                                                  onClick={(e) => {
                                                    e.stopPropagation();
                                                    handleCopy(note.summary, setCopiedSummary);
                                                  }}
                                                  className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                                >
                                                  <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                                  {copiedSummary ? "Copied" : "Copy"}
                                                </button>
                                              )}
                                            </>
                                          )}
                                        </div>
                                      </div>
                                      <div className="bg-primary-50 rounded-md p-4">
                                        {editingSummary === note.id ? (
                                          <textarea
                                            value={editedSummaryText}
                                            onChange={(e) =>
                                              setEditedSummaryText(e.target.value)
                                            }
                                            onClick={(e) => e.stopPropagation()}
                                            className="w-full min-h-[100px] p-2 text-sm text-gray-600 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                                            placeholder="Enter summary..."
                                          />
                                        ) : (
                                          <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                            {note.summary ||
                                              "AI Note Assistant not available"}
                                          </p>
                                        )}
                                      </div>
                                    </div>

                                    {/* Transcript Section */}
                                    <div className="bg-white rounded-lg p-4 shadow-sm">
                                      <div className="flex justify-between items-center mb-3">
                                        <h4 className="text-sm font-medium text-gray-900">
                                          {note?.record_type_format === 'text' ? 'Typed Notes' : 'Transcript'}
                                        </h4>
                                        {note.transcript && (
                                          <button
                                            onClick={(e) => {
                                              e.stopPropagation();
                                              handleCopy(
                                                note.transcript,
                                                setCopiedTranscript
                                              );
                                            }}
                                            className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                          >
                                            <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                            {copiedTranscript ? "Copied" : "Copy"}
                                          </button>
                                        )}
                                      </div>
                                      <div className="bg-gray-50 rounded-md p-4">
                                        <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                          {note.transcript ||
                                            (note?.record_type_format === 'text' ? "Typed Notes not available" : "Transcript not available")}
                                        </p>
                                      </div>
                                    </div>

                                    {/* Additional Notes Section - Only show if not text format */}
                                    {note.record_type_format !== 'text' && (
                                      <div className="bg-white rounded-lg p-4 shadow-sm">
                                        <div className="flex justify-between items-center mb-3">
                                          <h4 className="text-sm font-medium text-gray-900">
                                            Additional Notes
                                          </h4>
                                          {note.additional_notes && (
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleCopy(
                                                  note.additional_notes,
                                                  setCopiedAdditionalNotes
                                                );
                                              }}
                                              className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                            >
                                              <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                              {copiedAdditionalNotes
                                                ? "Copied"
                                                : "Copy"}
                                            </button>
                                          )}
                                        </div>
                                        <div className="bg-gray-50 rounded-md p-4">
                                          <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                            {note.additional_notes ||
                                              "No additional notes"}
                                          </p>
                                        </div>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </div>
                            )}
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                )}
              </div>
            </div>
          </Tab.Panel>

          {/* Observations Panel */}
          <Tab.Panel>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
              <h2 className="text-2xl font-bold text-gray-900">Observations</h2>
            </div>

            <div className="bg-white shadow overflow-hidden sm:rounded-md p-4">
              {loadingObservations ? (
                <div className="flex justify-center items-center py-12">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
                </div>
              ) : !observationSummaries?.length ? (
                <div className="text-center py-12">
                  <p className="text-gray-500">No observations available</p>
                </div>
              ) : (
                <div className="space-y-4">
                  {observationSummaries.map((summary) => (
                    <div key={summary.id} className="bg-gray-50 rounded-lg p-4">
                      <div
                        className="cursor-pointer"
                        onClick={() => setExpandedObservationId(expandedObservationId === summary.id ? null : summary.id)}
                      >
                        <div className="flex justify-between items-center mb-3">
                          <div>
                            <h4 className="text-sm font-medium text-gray-900">
                              {formatRelativeDateTime(summary.observation_date)}
                            </h4>
                          </div>
                          <div className="flex items-center space-x-2">
                            {summary.observation_summary && (
                              <button
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleCopyObservation(summary.id);
                                }}
                                className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                              >
                                <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                {copiedObservations ? "Copied" : "Copy"}
                              </button>
                            )}
                            <button
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRegenerateObservation(summary.id, summary.observation_date, childName);
                              }}
                              disabled={regeneratingObservationId === summary.id}
                              className="inline-flex items-center text-sm text-primary-600 hover:text-primary-700 px-2 py-1 rounded-md hover:bg-primary-50 disabled:opacity-50 disabled:cursor-not-allowed"
                            >
                              {regeneratingObservationId === summary.id ? (
                                <>
                                  <svg
                                    className="animate-spin h-4 w-4 mr-1"
                                    xmlns="http://www.w3.org/2000/svg"
                                    fill="none"
                                    viewBox="0 0 24 24"
                                  >
                                    <circle
                                      className="opacity-25"
                                      cx="12"
                                      cy="12"
                                      r="10"
                                      stroke="currentColor"
                                      strokeWidth="4"
                                    />
                                    <path
                                      className="opacity-75"
                                      fill="currentColor"
                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                    />
                                  </svg>
                                  Regenerating...
                                </>
                              ) : (
                                <>
                                  <ArrowPathIcon className="h-4 w-4 mr-1" />
                                  Regenerate
                                </>
                              )}
                            </button>
                            <ExpandIcon isExpanded={expandedObservationId === summary.id} />
                          </div>
                        </div>

                        {/* Preview when not expanded */}
                        {expandedObservationId !== summary.id && (
                          <p className="text-sm text-gray-600 line-clamp-2">
                            {summary.observation_summary || "No observations available"}
                          </p>
                        )}

                        {/* Expanded Content */}
                        {expandedObservationId === summary.id && (
                          <div className="mt-4 space-y-4">
                            <div className="bg-white rounded-lg p-4 shadow-sm">
                              <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                {summary.observation_summary || "No observations available"}
                              </p>

                              {/* Rest of the expanded content remains the same */}
                              {summary.record_ids && summary.record_ids.length > 0 && (
                                <div className="mt-4">
                                  <h5 className="text-sm font-medium text-gray-900 mb-2">
                                    Included Records ({summary.record_ids.length}):
                                  </h5>
                                  <div className="space-y-2">
                                    {summary.record_ids
                                      .map(recordId => {
                                        const noteDetails = notes?.records?.find(note => note.id === recordId);
                                        return { recordId, noteDetails };
                                      })
                                      .sort((a, b) => {
                                        const dateA = new Date(a.noteDetails?.created_at || 0);
                                        const dateB = new Date(b.noteDetails?.created_at || 0);
                                        return dateB - dateA;
                                      })
                                      .map(({ recordId, noteDetails }) => (
                                        <div
                                          key={recordId}
                                          className="text-sm text-gray-600 bg-gray-50 p-4 rounded hover:bg-gray-100 cursor-pointer"
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            fetchRecordDetailsHandler(recordId);
                                          }}
                                        >
                                          <div className="flex flex-col space-y-2">
                                            <div className="flex justify-between items-start">
                                              <span className="font-medium text-gray-900">
                                                By: {noteDetails?.user_name || 'Not Available'}
                                              </span>
                                              <span className="text-gray-500 text-xs">
                                                {noteDetails?.created_at ? formatDateTime(noteDetails.created_at) : ""}
                                              </span>
                                            </div>
                                            <p className="text-gray-600 line-clamp-2">
                                              {noteDetails?.summary || "Summary not available"}
                                            </p>
                                          </div>
                                        </div>
                                      ))}
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </Tab.Panel>

          {/* Summaries Panel - Move existing Summaries panel content here */}
          <Tab.Panel>
            <div className="flex flex-col sm:flex-row justify-between items-start sm:items-center gap-4 mb-4">
              <h2 className="text-2xl font-bold text-gray-900">
                Your Summaries
              </h2>
            </div>

            <div className="bg-white shadow overflow-hidden sm:rounded-md p-4">
              {!loadingSummaries && summarizedNotes && (
                <FilterPanel
                  filters={summaryFilters}
                  onFilterChange={handleSummaryFiltersChange}
                  availableCategories={categoriesByRecordType}
                  notes={notes?.records || []}
                />
              )}

              {/* Loading State */}
              {loadingSummaries && (
                <div className="flex justify-center items-center py-12">
                  <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
                </div>
              )}

              {/* No Summaries State */}
              {!loadingSummaries &&
                (!summarizedNotes || summarizedNotes.length === 0) && (
                  <div className="text-center py-12">
                    <p className="text-gray-500">No summaries found</p>
                  </div>
                )}

              {/* Summaries List */}
              {!loadingSummaries && summarizedNotes.length > 0 && filteredSummaries.length > 0 && (
                <>
                  <div className="p-4 border-b flex items-center justify-between bg-gray-50">
                    <div className="flex items-center space-x-4">
                      <input
                        type="checkbox"
                        checked={
                          filteredSummaries.length > 0 &&
                          filteredSummaries.every((summary) =>
                            selectedSummaries.includes(summary.id)
                          )
                        }
                        onChange={(e) => handleSelectAllNotesSummariesWrapper(e.target.checked)}
                        className="h-4 w-4 text-primary-600 focus:ring-primary-500 border-gray-300 rounded"
                      />
                      <span className="text-sm text-gray-600">
                        {selectedSummaries.length} selected
                      </span>
                    </div>
                    {selectedSummaries.length > 0 && (
                      <button
                        onClick={handleDeleteSummariesWrapper}
                        disabled={isBulkDeletingSummaries}
                        className="inline-flex items-center px-3 py-1.5 border border-transparent text-sm font-medium rounded-md text-white bg-red-600 hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 disabled:opacity-75 disabled:cursor-not-allowed"
                      >
                        {isBulkDeletingSummaries ? (
                          <>
                            <svg
                              className="animate-spin -ml-1 mr-2 h-4 w-4 text-white"
                              xmlns="http://www.w3.org/2000/svg"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <circle
                                className="opacity-25"
                                cx="12"
                                cy="12"
                                r="10"
                                stroke="currentColor"
                                strokeWidth="4"
                              />
                              <path
                                className="opacity-75"
                                fill="currentColor"
                                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                              />
                            </svg>
                            Deleting...
                          </>
                        ) : (
                          <>
                            <TrashIcon className="h-4 w-4 mr-1.5" />
                            Delete
                          </>
                        )}
                      </button>
                    )}
                  </div>

                  {/* Show "Notes not available" if filtered data is empty */}
                  {filteredSummaries.length === 0 ? (
                    <div className="text-center py-12">
                      <p className="text-gray-500">Notes not available</p>
                    </div>
                  ) : (
                    <ul className="divide-y-0">
                      {filteredSummaries.map((summary) => (
                        <li
                          key={summary.id}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSummaryRowClickWrapper(summary.id);
                          }}
                          className={`border-l-4 transition-colors duration-200 cursor-pointer ${expandedSummaryId === summary.id
                            ? "border-l-primary-600 bg-gray-50"
                            : "border-l-transparent hover:bg-gray-50"
                            } ${selectedSummaries.includes(summary.id)
                              ? "bg-primary-50"
                              : ""
                            }`}
                        >
                          <div className="px-4 py-4 sm:px-6">
                            <ListItemHeader
                              isSelected={selectedSummaries.includes(
                                summary.id
                              )}
                              onCheckboxChange={() =>
                                handleSummarySelectionWrapper(
                                  summary.id
                                )
                              }
                              title={`${summary.records.length} Note(s) Summary${summary.records[0]?.record_type === 'home_note' && summary.records[0]?.home_name
                                ? ` - ${summary.records[0].home_name}`
                                : summary.records[0]?.record_type === 'child_note' && summary.records[0]?.child_name
                                  ? ` - ${summary.records[0].child_name}`
                                  : ''
                                }`}
                              timestamp={formatDateTime(summary?.created_at)}
                              userName={summary.user_name}
                              customPromptName={summary.custom_prompt_name}
                              backendPromptName={null}
                              onDelete={() => {
                                setSelectedSummaries([
                                  summary.id,
                                ]);
                                setDeletingSummaryId(summary.id);
                                handleDeleteSummariesWrapper();
                              }}
                              isDeleting={
                                deletingSummaryId === summary.id
                              }
                              isExpanded={
                                expandedSummaryId === summary.id
                              }
                            />

                            {/* Preview when not expanded */}
                            {expandedSummaryId !==
                              summary.id && (
                                <div className="mt-2">
                                  <p className="text-sm text-gray-600 line-clamp-2">
                                    {summary.summary || "Summary not available"}
                                  </p>
                                </div>
                              )}

                            {/* Expanded View */}
                            {expandedSummaryId ===
                              summary.id && (
                                <div
                                  className="mt-4 space-y-4"
                                  onClick={(e) => e.stopPropagation()}
                                >
                                  <div className="bg-white rounded-lg p-4 shadow-sm">
                                    <div className="flex justify-between items-center mb-3">
                                      <h4 className="text-sm font-medium text-gray-900">
                                        Generated Summary
                                      </h4>
                                      <div className="flex items-center space-x-2">
                                        {editingGeneratedSummary === summary.id ? (
                                          <>
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                handleUpdateCustomSummaryWrapper(summary.id);
                                              }}
                                              className="inline-flex items-center text-sm text-green-600 hover:text-green-700 px-2 py-1 rounded-md hover:bg-green-50 disabled:opacity-50 disabled:cursor-not-allowed"
                                            >
                                              {updatingGeneratedSummaryId === summary.id ? (
                                                <>
                                                  <svg
                                                    className="animate-spin h-4 w-4 mr-1"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    fill="none"
                                                    viewBox="0 0 24 24"
                                                  >
                                                    <circle
                                                      className="opacity-25"
                                                      cx="12"
                                                      cy="12"
                                                      r="10"
                                                      stroke="currentColor"
                                                      strokeWidth="4"
                                                    />
                                                    <path
                                                      className="opacity-75"
                                                      fill="currentColor"
                                                      d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                                                    />
                                                  </svg>
                                                  Saving...
                                                </>
                                              ) : (
                                                <>
                                                  <CheckIcon className="h-4 w-4 mr-1" />
                                                  Save
                                                </>
                                              )}
                                            </button>
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setEditingGeneratedSummary(null);
                                                setEditedGeneratedSummaryText("");
                                              }}
                                              className="inline-flex items-center text-sm text-red-600 hover:text-red-700 px-2 py-1 rounded-md hover:bg-red-50"
                                            >
                                              <XMarkIcon className="h-4 w-4 mr-1" />
                                              Cancel
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            <button
                                              onClick={(e) => {
                                                e.stopPropagation();
                                                setEditingGeneratedSummary(summary.id);
                                                setEditedGeneratedSummaryText(summary.summary || "");
                                              }}
                                              className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                            >
                                              <PencilIcon className="h-4 w-4 mr-1" />
                                              Edit
                                            </button>
                                            {summary.summary && (
                                              <button
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  handleCopy(summary.summary, setCopiedGeneratedSummary);
                                                }}
                                                className="inline-flex items-center text-sm text-gray-500 hover:text-gray-700 px-2 py-1 rounded-md hover:bg-gray-50"
                                              >
                                                <ClipboardDocumentIcon className="h-4 w-4 mr-1" />
                                                {copiedGeneratedSummary ? "Copied" : "Copy"}
                                              </button>
                                            )}
                                          </>
                                        )}
                                      </div>
                                    </div>
                                    <div className="bg-primary-50 rounded-md p-4">
                                      {editingGeneratedSummary === summary.id ? (
                                        <textarea
                                          value={editedGeneratedSummaryText}
                                          onChange={(e) => setEditedGeneratedSummaryText(e.target.value)}
                                          onClick={(e) => e.stopPropagation()}
                                          className="w-full min-h-[100px] p-2 text-sm text-gray-600 border border-gray-300 rounded-md focus:ring-primary-500 focus:border-primary-500"
                                          placeholder="Enter summary..."
                                        />
                                      ) : (
                                        <p className="text-sm text-gray-600 whitespace-pre-wrap">
                                          {summary.summary || "Summary not available"}
                                        </p>
                                      )}
                                    </div>
                                  </div>

                                  <div className="bg-white rounded-lg p-4 shadow-sm">
                                    <h4 className="text-sm font-medium text-gray-900 mb-3">
                                      Included Notes
                                    </h4>
                                    <div className="space-y-2">
                                      {summary.records.map((record) => (
                                        <div
                                          key={record.id}
                                          className="bg-gray-50 rounded-md p-4 cursor-pointer hover:bg-gray-100"
                                          onClick={() =>
                                            fetchRecordDetailsHandler(record.id)
                                          }
                                        >
                                          <div className="flex justify-between">
                                            <div>
                                              <p className="text-sm font-medium text-gray-900">
                                                Record ID: {record.record_id_int}
                                              </p>
                                              <p className="text-sm text-gray-500">
                                                {record.category
                                                  ?.replace(/_/g, " ")
                                                  .replace(/\b\w/g, (l) =>
                                                    l.toUpperCase()
                                                  )}
                                              </p>
                                            </div>
                                            <span className="text-sm text-gray-500">
                                              {formatDateTime(record.created_at)}
                                            </span>
                                          </div>
                                        </div>
                                      ))}
                                    </div>
                                  </div>
                                </div>
                              )}
                          </div>
                        </li>
                      ))}
                    </ul>
                  )}
                </>
              )}
            </div>
          </Tab.Panel>
        </Tab.Panels>
      </Tab.Group>

      {/* Summarize Modal */}
      {showSummarizeModal && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-lg w-full">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Summarize {selectedNotes.length} Note(s)
              </h3>
              <button
                onClick={() => setShowSummarizeModal(false)}
                className="text-gray-400 hover:text-gray-500"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <div className="space-y-4">
              <div>
                <label className="block text-sm font-semibold text-gray-700 tracking-wide uppercase mb-2">
                  Selected Notes
                </label>
                <div className="mt-2 max-h-40 overflow-y-auto">
                  {notes?.records
                    ?.filter((note) => selectedNotes.includes(note?.id))
                    ?.sort((a, b) => new Date(a.created_at) - new Date(b.created_at))
                    ?.map((note) => (
                      <div key={note?.id} className="flex items-center py-2">
                        <DocumentTextIcon className="h-5 w-5 text-gray-400 mr-2" />
                        <span className="text-sm text-gray-600">
                          {note?.category
                            ?.replace(/_/g, " ")
                            ?.replace(/\b\w/g, (l) => l.toUpperCase())}{" "}
                          - {formatDateTime(note?.created_at)}
                        </span>
                      </div>
                    ))}
                </div>
              </div>
              <div>
                <label className="block text-sm font-semibold text-gray-700 tracking-wide uppercase mb-2">
                  Custom Instructions
                </label>
                <div className="space-y-4">
                  <div className="space-y-2">
                    {loadingPrompts ? (
                      <div className="flex justify-center items-center py-8">
                        <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary-600"></div>
                      </div>
                    ) : predefinedPrompts.length > 0 ? (
                      <RadioGroup
                        value={selectedPredefinedPrompts[0] || null}
                        onChange={(value) =>
                          setSelectedPredefinedPrompts(value ? [value] : [])
                        }
                      >
                        <div className="space-y-2">
                          {predefinedPrompts.map((prompt) => (
                            <RadioGroup.Option
                              key={prompt.id}
                              value={prompt}
                              className={({ checked }) => `
                                relative flex cursor-pointer rounded-lg border p-4 focus:outline-none
                                ${checked
                                  ? "bg-primary-50 border-primary-500 text-primary-700"
                                  : "bg-white border-gray-300 hover:bg-gray-50"
                                }
                              `}
                            >
                              {({ checked }) => (
                                <div className="flex w-full items-center justify-between">
                                  <div className="flex items-center">
                                    <div className="text-sm">
                                      <RadioGroup.Label
                                        className={`font-medium ${checked
                                          ? "text-primary-700"
                                          : "text-gray-900"
                                          }`}
                                      >
                                        {prompt.title}
                                      </RadioGroup.Label>
                                    </div>
                                  </div>
                                  <div
                                    className={`shrink-0 ${checked
                                      ? "text-primary-600"
                                      : "text-gray-400"
                                      }`}
                                  >
                                    <div
                                      className={`
                                      h-4 w-4 rounded-full border flex items-center justify-center
                                      ${checked
                                          ? "border-primary-600 bg-primary-600"
                                          : "border-gray-300"
                                        }
                                    `}
                                    >
                                      <div
                                        className={`
                                        h-2 w-2 rounded-full bg-white
                                        ${checked ? "opacity-100" : "opacity-0"}
                                      `}
                                      />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </RadioGroup.Option>
                          ))}
                        </div>
                      </RadioGroup>
                    ) : (
                      <div className="text-sm text-gray-500 italic p-4 bg-gray-50 rounded-lg">
                        No predefined instructions available for these notes
                      </div>
                    )}
                  </div>

                  <div className="mt-4">
                    <p className="text-sm text-gray-500 mb-2">
                      {predefinedPrompts.length > 0 ? "Or write" : "Write"} your
                      own custom instructions:
                    </p>
                    <textarea
                      value={customPrompt}
                      onChange={(e) => {
                        setCustomPrompt(e.target.value);
                        setSelectedPredefinedPrompts([]);
                      }}
                      className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500"
                      rows={4}
                      placeholder="E.g., Summarize the child's behavior patterns across these observations..."
                    />
                  </div>
                </div>
              </div>
              <div className="flex justify-end space-x-3">
                <button
                  onClick={() => setShowSummarizeModal(false)}
                  disabled={isSummarizing}
                  className={`px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50 border border-gray-300 rounded-md ${isSummarizing ? "opacity-50 cursor-not-allowed" : ""
                    }`}
                >
                  Cancel
                </button>
                <button
                  onClick={handleSummarizeWrapper}
                  disabled={isSummarizing}
                  className={`px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md inline-flex items-center justify-center min-w-[140px] ${isSummarizing
                    ? "opacity-75 cursor-not-allowed"
                    : "hover:bg-primary-700"
                    }`}
                >
                  {isSummarizing ? (
                    <>
                      <svg
                        className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        />
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        />
                      </svg>
                      Generating...
                    </>
                  ) : (
                    <>
                      <DocumentDuplicateIcon className="h-5 w-5 mr-2" />
                      Generate Summary
                    </>
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      )}

      {/* Add this new Record Details Modal */}
      {selectedRecordDetails && (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-lg p-6 max-w-3xl w-full max-h-[90vh] overflow-y-auto">
            <div className="flex justify-between items-start mb-4">
              <h3 className="text-lg font-medium text-gray-900">
                Record Details{" "}
                <span className="text-gray-500">
                  ({selectedRecordDetails.record_id_int})
                </span>
              </h3>
              <button
                onClick={() => {
                  setSelectedRecordDetails(null);
                }}
                className="text-gray-400 hover:text-gray-500"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            {loadingRecordDetails ? (
              <div className="flex justify-center items-center py-12">
                <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-primary-600"></div>
              </div>
            ) : (
              <div className="space-y-4">
                {/* Record Type and Category */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <div className="grid grid-cols-2 gap-4">
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Record Type
                      </p>
                      <p className="text-sm text-gray-900">
                        {selectedRecordDetails?.record_type
                          ?.split("_")[0]
                          ?.charAt(0)
                          ?.toUpperCase() +
                          selectedRecordDetails?.record_type
                            ?.split("_")[0]
                            ?.slice(1)}{" "}
                        Note
                      </p>
                    </div>
                    <div>
                      <p className="text-sm font-medium text-gray-500">
                        Category
                      </p>
                      <p className="text-sm text-gray-900">
                        {selectedRecordDetails?.category
                          ?.replace(/_/g, " ")
                          .replace(/\b\w/g, (l) => l.toUpperCase())}
                      </p>
                    </div>
                  </div>
                </div>

                {/* Audio Player */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">
                    Audio Recording
                  </h4>
                  <AudioPlayer
                    recordId={selectedRecordDetails.id}
                    record_type_format={selectedRecordDetails.record_type_format}
                  />
                </div>

                {/* AI Note Assistant */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">
                    AI Note Assistant
                  </h4>
                  <p className="text-sm text-gray-600 whitespace-pre-wrap">
                    {selectedRecordDetails.summary || "Summary not available"}
                  </p>
                </div>

                {/* Transcript */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <h4 className="text-sm font-medium text-gray-900 mb-3">
                    {selectedRecordDetails?.record_type_format === 'text' ? 'Typed Notes' : 'Transcript'}
                  </h4>
                  <p className="text-sm text-gray-600 whitespace-pre-wrap">
                    {selectedRecordDetails.transcript ||
                      (selectedRecordDetails?.record_type_format === 'text'
                        ? "Typed Notes not available"
                        : "Transcript not available")}
                  </p>
                </div>

                {/* Additional Notes - Only show if not text format */}
                {selectedRecordDetails?.record_type_format !== 'text' && (
                  <div className="bg-gray-50 rounded-lg p-4">
                    <h4 className="text-sm font-medium text-gray-900 mb-3">
                      Additional Notes
                    </h4>
                    <p className="text-sm text-gray-600 whitespace-pre-wrap">
                      {selectedRecordDetails.additional_notes ||
                        "No additional notes"}
                    </p>
                  </div>
                )}

                {/* Metadata */}
                <div className="bg-gray-50 rounded-lg p-4">
                  <div>
                    <p className="text-sm font-medium text-gray-500">
                      Updated Date
                    </p>
                    <p className="text-sm text-gray-900">
                      {formatDateTime(selectedRecordDetails.created_at)}
                    </p>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
}

export default NotesView;
