import React, { useEffect, useState } from "react";
import { fetchAudioUrl } from "../../api/apiConfig";

const AudioPlayer = ({ recordId, record_type_format }) => {
  const [audioUrl, setAudioUrl] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (recordId) {
      setIsLoading(true);
      fetchAudioUrl(recordId)
        .then(setAudioUrl)
        .finally(() => setIsLoading(false));
    }
  }, [recordId]);

  // If it's a text note, show "Audio not available"
  if (record_type_format === 'text') {
    return (
      <div onClick={e => e.stopPropagation()}>
        <p className="text-sm text-gray-500 italic">Audio not available as the record is a Typed Note</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="flex items-center justify-center py-4" onClick={e => e.stopPropagation()}>
        <div className="animate-spin rounded-full h-6 w-6 border-b-2 border-primary-600"></div>
        <span className="ml-2 text-sm text-gray-500">Loading audio...</span>
      </div>
    );
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <audio
        src={audioUrl}
        controls
        className="w-full"
        controlsList="nodownload"
        onError={(e) => {
          e.target.outerHTML =
            '<p class="text-sm text-gray-500 italic">Audio not available</p>';
        }}
      />
    </div>
  );
};

export default AudioPlayer; 