import { apiClient, API_ENDPOINTS } from '../../api/apiConfig';
import { useState } from 'react';
import { getFormattedDate } from '../../utils/constants';

export const useObservationSummary = () => {
    const [observationSummaries, setObservationSummaries] = useState([]);
    const [loadingObservations, setLoadingObservations] = useState(false);
    const [copiedObservations, setCopiedObservations] = useState(false);
    const [regeneratingObservationId, setRegeneratingObservationId] = useState(null);

    const fetchObservationSummaryHandler = async (childName) => {
        setLoadingObservations(true);
        try {
            const response = await apiClient.get(
                API_ENDPOINTS.GET_OBSERVATIONS_SUMMARY(childName)
            );
            setObservationSummaries(response.observation_summary || []);
        } catch (error) {
            console.error('Error fetching observation summaries:', error);
        } finally {
            setLoadingObservations(false);
        }
    };

    const handleCopyObservation = (summaryId) => {
        const summary = observationSummaries.find(s => s.id === summaryId);
        if (summary?.observation_summary) {
            navigator.clipboard.writeText(summary.observation_summary);
            setCopiedObservations(true);
            setTimeout(() => setCopiedObservations(false), 2000);
        }
    };

    const handleRegenerateObservation = async (observationId, date, childName) => {
        setRegeneratingObservationId(observationId);
        try {
            const response = await apiClient.post(
                API_ENDPOINTS.REGENERATE_OBSERVATIONS_SUMMARY,
                {
                    date: date,
                    child_name: childName
                }
            );
            if (response.observation_summary) {
                setObservationSummaries(response.observation_summary);
            }
            fetchObservationSummaryHandler(childName);
        } catch (error) {
            console.error('Error regenerating observation:', error);
        } finally {
            setRegeneratingObservationId(null);
        }
    };

    return {
        observationSummaries,
        loadingObservations,
        copiedObservations,
        regeneratingObservationId,
        fetchObservationSummaryHandler,
        handleCopyObservation,
        handleRegenerateObservation
    };
};
