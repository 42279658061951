import React, { useState, useEffect } from "react";
import { XMarkIcon } from "@heroicons/react/24/outline";

const SelectHomePopover = ({ onClose, onSelectHome }) => {
    const [homes, setHomes] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [selectedHome, setSelectedHome] = useState(null);

    useEffect(() => {
        const storedHomeId = sessionStorage.getItem('organizationId');
        const storedHome = sessionStorage.getItem('organizationName');
        if (storedHome) {
            // Directly select the home and close the popover
            onSelectHome({
                id: storedHomeId,
                name: storedHome,
            });
            onClose();
        } else {
            setError("No home found in session storage");
        }
    }, [onSelectHome, onClose]);

    const handleHomeSelect = (home) => {
        setSelectedHome(home);
        onSelectHome({
            id: home.id,
            name: home.home_name,
        });
    };

    return (
        <div className="fixed inset-0 bg-gray-500 bg-opacity-75 flex items-center justify-center z-50 p-4">
            <div className="bg-white rounded-2xl shadow-xl w-full max-w-md transform transition-all">
                <div className="flex justify-between items-center p-6 border-b border-gray-100">
                    <h3 className="text-xl font-semibold text-gray-800">Select Home to Start Recording</h3>
                    <button
                        onClick={onClose}
                        className="p-2 rounded-full hover:bg-gray-100 transition-colors"
                    >
                        <XMarkIcon className="h-5 w-5 text-gray-500" />
                    </button>
                </div>

                {isLoading ? (
                    <div className="text-center py-12">
                        <div className="animate-spin rounded-full h-10 w-10 border-t-2 border-b-2 border-primary-600 mx-auto"></div>
                        <p className="mt-4 text-sm text-gray-600">Loading homes...</p>
                    </div>
                ) : error ? (
                    <div className="text-red-600 text-center py-12">
                        <p className="text-base">{error}</p>
                        <button
                            onClick={() => {
                                const storedHomes = sessionStorage.getItem('homes');
                                try {
                                    const parsedHomes = storedHomes ? JSON.parse(storedHomes) : [];
                                    setHomes(parsedHomes);
                                } catch (err) {
                                    setError("Failed to load homes");
                                    console.error("Error parsing homes from session storage:", err);
                                }
                            }}
                            className="mt-4 text-primary-600 hover:text-primary-700 font-medium"
                        >
                            Try again
                        </button>
                    </div>
                ) : (
                    <div className="max-h-[400px] overflow-y-auto px-2">
                        {homes.length === 0 ? (
                            <div className="text-center py-12">
                                <p className="text-gray-600">No homes found.</p>
                            </div>
                        ) : (
                            <div className="py-2">
                                {homes.map((home) => (
                                    <button
                                        key={home.id}
                                        onClick={() => handleHomeSelect(home)}
                                        className={`w-full px-6 py-4 text-left rounded-xl mb-2 transition-all
                                            ${selectedHome?.id === home.id
                                                ? "bg-primary-50 text-primary-700 font-medium"
                                                : "text-gray-700 hover:bg-gray-50"
                                            }`}
                                    >
                                        {home.home_name}
                                    </button>
                                ))}
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    );
};

export default SelectHomePopover; 