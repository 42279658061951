import React from 'react';
import { UserCircleIcon } from '@heroicons/react/24/outline';
import { useAuth0 } from "@auth0/auth0-react";
import { SettingsSection } from '../../components/Settings';

const AccountSection = () => {
    const { logout, user, isAuthenticated } = useAuth0();
    const userRole = sessionStorage.getItem('userRole');
    const organizationName = sessionStorage.getItem('organizationName');

    const handleSignOut = async () => {
        try {
            // Clear session storage
            sessionStorage.clear();
            await logout({
                returnTo: window.location.origin
            });
        } catch (error) {
            console.error('Error signing out:', error);
        }
    };

    return (
        <SettingsSection title="Account Information" icon={UserCircleIcon}>
            <div className="space-y-4">
                <div>
                    <label className="block text-sm font-medium text-gray-700">Email</label>
                    <p className="mt-1 text-sm text-gray-500">
                        {isAuthenticated ? user.email : 'Not available'}
                    </p>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Name</label>
                    <p className="mt-1 text-sm text-gray-500">
                        {isAuthenticated ? user.name : 'Not available'}
                    </p>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Role</label>
                    <p className="mt-1 text-sm text-gray-500">
                        {isAuthenticated && userRole ? userRole : 'Not available'}
                    </p>
                </div>
                <div>
                    <label className="block text-sm font-medium text-gray-700">Home Name</label>
                    <p className="mt-1 text-sm text-gray-500">
                        {isAuthenticated && organizationName ? organizationName : 'Not available'}
                    </p>
                </div>
                <div className="pt-2 flex space-x-4">
                    <button
                        onClick={handleSignOut}
                        className="inline-flex items-center px-4 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
                    >
                        Sign Out
                    </button>
                </div>
            </div>
        </SettingsSection>
    );
};

export default AccountSection; 