import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import { formatShiftDate } from "../../utils/constants";
import { CalendarIcon } from "@heroicons/react/24/outline";

function DateSelector({ selectedCategory, currentDate, onDateChange }) {
    if (selectedCategory !== 'child') return null;

    return (
        <div className="mb-6">
            <div className="relative inline-block">
                <div className="inline-flex items-center px-4 py-2 text-sm font-medium text-white bg-primary-600 border border-transparent rounded-md hover:bg-primary-700 focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500">
                    <span className="whitespace-nowrap mr-2">Recording For:</span>
                    <DatePicker
                        selected={currentDate}
                        onChange={onDateChange}
                        maxDate={new Date()}
                        dateFormat="dd MMM yyyy"
                        className="bg-transparent text-white cursor-pointer focus:outline-none"
                        calendarClassName="bg-white shadow-lg rounded-lg border border-gray-200"
                        showMonthDropdown
                        showYearDropdown
                        dropdownMode="select"
                        customInput={
                            <button type="button" className="inline-flex items-center space-x-2">
                                <span className="whitespace-nowrap">{formatShiftDate(currentDate)}</span>
                                <CalendarIcon className="h-4 w-4" />
                            </button>
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default DateSelector;
