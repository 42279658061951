import React from "react";
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import {
    RiEmotionHappyLine,
    RiEmotionNormalLine,
    RiEmotionUnhappyLine,
} from "react-icons/ri";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend
);

function DailyMoodFluctuation({ events }) {
    const getPointColor = (quantification) => {
        if (quantification >= 7) return 'rgb(239, 68, 68)';  // red
        if (quantification >= 4) return 'rgb(234, 179, 8)';  // amber
        if (quantification <= 3) return 'rgb(34, 197, 94)';  // green
        return 'rgb(75, 192, 192)' // neutral line color "teal"
    };

    const data = {
        labels: events?.map(event => event.time) || [],
        datasets: [
            {
                label: 'Mood Level',
                data: events?.map(event => event.quantification),
                fill: false,
                tension: 0.4,
                pointBackgroundColor: events?.map(event => getPointColor(event.quantification)),
                pointBorderColor: events?.map(event => getPointColor(event.quantification)),
                segment: {
                    borderColor: (ctx) => {
                        if (!ctx.p0.parsed || !ctx.p1.parsed) return 'rgb(75, 192, 192)'; // teal

                        const value1 = ctx.p0.parsed.y;
                        const value2 = ctx.p1.parsed.y;

                        // If either point is >= 7, make the connecting line red
                        if (value1 >= 7 || value2 >= 7) return 'rgb(239, 68, 68)';

                        // If either point is >= 4, make the connecting line amber
                        if (value1 >= 4 || value2 >= 4) return 'rgb(234, 179, 8)';

                        // If both points are <= 3, make the connecting line green
                        if (value1 <= 3 && value2 <= 3) return 'rgb(34, 197, 94)';

                        return 'rgb(75, 192, 192)'; // teal for neutral cases
                    }
                }
            }
        ]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                position: "top",
            },
            title: {
                display: false,
                text: "Daily Mood Fluctuation",
            },
            tooltip: {
                enabled: true,
                mode: 'index',
                intersect: false,
                callbacks: {
                    label: function (context) {
                        const event = events[context.dataIndex];
                        return [
                            `${event.summary}`
                        ];
                    }
                }
            }
        },
        scales: {
            y: {
                min: 0,
                max: 10,
                grid: {
                    display: false,
                    drawTicks: true,
                },
                ticks: {
                    display: false,
                },
            },
            x: {
                grid: {
                    display: false,
                },
            },
        },
    };

    return (
        <div className="mt-8">
            {/* <h3 className="text-xl font-semibold mb-4">Daily Mood Fluctuation</h3> */}
            <div className="mt-2 bg-white shadow sm:rounded-md p-4 relative">
                <div className="absolute inset-y-0 flex flex-col justify-between py-16 pointer-events-none">
                    <div className="flex items-center">
                        <RiEmotionUnhappyLine className="w-12 h-12 text-red-500" />
                    </div>
                    <div className="flex items-center">
                        <RiEmotionNormalLine className="w-12 h-12 text-amber-500" />
                    </div>
                    <div className="flex items-center">
                        <RiEmotionHappyLine className="w-12 h-12 text-green-500" />
                    </div>
                </div>
                <div className="h-[530px] ml-10">
                    <Line data={data} options={options} />
                </div>
            </div>
        </div>
    );
}

export default DailyMoodFluctuation;
