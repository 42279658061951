import { store } from '../store/store';
import { clearAudioState } from '../store/slices/audioSlice';
import { apiClient, API_ENDPOINTS } from '../api/apiConfig';
import { setIsRecording } from '../store/slices/uiSlice';

export class AudioService {
  constructor() {
    this.websocket = null;
    this.audioContext = null;
    this.audioSource = null;
    this.audioProcessor = null;
    this.stream = null;
    this.conversationId = null;
    this.audioSettings = {
      bufferSize: 2048,
      sampleRate: 44100,
      gainValue: 1.0,
      latencyHint: 'interactive',
      amplitudeThreshold: 0.95,
      limitingRatio: 4,
    };
    this.isPaused = false;
    this.pauseTimeout = null;
    this.WEBSOCKET_TIMEOUT = process.env.REACT_APP_WEBSOCKET_TIMEOUT
      ? parseInt(process.env.REACT_APP_WEBSOCKET_TIMEOUT, 10)
      : 60000;
  }

  async detectOptimalSettings() {
    const ua = navigator.userAgent.toLowerCase();
    const browserInfo = {
      isSamsung: ua.includes('samsungbrowser'),
      isSafari: ua.includes('safari') && !ua.includes('chrome'),
      isMobile: /mobile|android|iphone|ipad|ipod/i.test(ua)
    };

    if (browserInfo.isMobile) {
      Object.assign(this.audioSettings, {
        bufferSize: 4096,
        latencyHint: 'balanced',
        gainValue: 0.6,
        amplitudeThreshold: 0.7,
        limitingRatio: 10,
      });
    }

    if (browserInfo.isSamsung) {
      Object.assign(this.audioSettings, {
        sampleRate: 44100,
        bufferSize: 8192,
        gainValue: 0.5,
        amplitudeThreshold: 0.6,
        limitingRatio: 12,
      });
    }

    if (browserInfo.isSafari) {
      this.audioSettings.bufferSize = 4096;
      this.audioSettings.latencyHint = 'playback';
    }
  }

  async startRecording(websocketUrl) {
    try {
      await this.detectOptimalSettings();
      const supportedConstraints = navigator.mediaDevices.getSupportedConstraints();

      const audioConstraints = {
        audio: {
          channelCount: 1,
          echoCancellation: supportedConstraints.echoCancellation || true,
          noiseSuppression: supportedConstraints.noiseSuppression || true,
          autoGainControl: supportedConstraints.autoGainControl || true,
          latencyHint: this.audioSettings.latencyHint
        }
      };

      this.websocket = new WebSocket(websocketUrl);
      await new Promise((resolve, reject) => {
        this.websocket.onopen = resolve;
        this.websocket.onerror = reject;
      });

      this.audioContext = new window.AudioContext({
        latencyHint: this.audioSettings.latencyHint,
        sampleRate: this.audioSettings.sampleRate
      });

      this.stream = await navigator.mediaDevices.getUserMedia(audioConstraints);
      const settings = this.stream.getAudioTracks()[0].getSettings();
      this.audioSettings.sampleRate = Math.min(
        this.audioSettings.sampleRate,
        settings?.sampleRate || this.audioSettings.sampleRate
      );

      this.audioSource = this.audioContext.createMediaStreamSource(this.stream);
      this.audioProcessor = this.audioContext.createScriptProcessor(
        this.audioSettings.bufferSize, 1, 1
      );

      this.audioProcessor.onaudioprocess = (e) => {
        if (this.websocket?.readyState === WebSocket.OPEN && !this.isPaused) {
          const float32Array = e.inputBuffer?.getChannelData(0);
          const int16Array = new Int16Array(float32Array?.length);
          const { amplitudeThreshold, limitingRatio, sampleRate } = this.audioSettings;

          for (let i = 0; i < float32Array?.length; i++) {
            let sample = float32Array[i];

            if (Math.abs(sample) > amplitudeThreshold) {
              const excess = Math.abs(sample) - amplitudeThreshold;
              const compression = excess / limitingRatio;
              sample = (sample > 0 ? 1 : -1) * (amplitudeThreshold + compression);
            }

            if (sampleRate === 44100 && i > 0) {
              sample = (sample + float32Array[i - 1]) * 0.5;
            }

            int16Array[i] = Math.max(-32768, Math.min(32767, Math.round(sample * 32767)));
          }

          this.websocket?.send(int16Array.buffer);
        }
      };

      const gainNode = this.audioContext.createGain();
      gainNode.gain.value = this.audioSettings.gainValue;

      this.audioSource.connect(gainNode);
      gainNode.connect(this.audioProcessor);
      this.audioProcessor.connect(this.audioContext.destination);

    } catch (error) {
      throw error;
    }
  }

  async stopRecording() {
    try {
      if (this.audioProcessor) {
        this.audioProcessor?.disconnect();
        this.audioSource?.disconnect();
        this.audioProcessor = null;
        this.audioSource = null;
      }

      if (this.audioContext) {
        await this.audioContext?.close();
        this.audioContext = null;
      }

      if (this.websocket) {
        this.websocket?.close();
        this.websocket = null;
      }

      if (this.conversationId) {
        try {
          await this.endConversation(this.conversationId);
        } catch (error) {
          console.error('Error ending conversation:', error);
          // Continue with cleanup even if endConversation fails
        }
        this.conversationId = null;
      }

      // Always dispatch clearAudioState, regardless of any errors
      store.dispatch(clearAudioState());
    } catch (error) {
      console.error('Error in stopRecording:', error);
      // Still dispatch clearAudioState even if there are other errors
      store.dispatch(clearAudioState());
      throw error;
    }
  }

  setConversationId(conversationId) {
    this.conversationId = conversationId;
  }

  async endConversation(conversationId) {
    await apiClient.post(`${API_ENDPOINTS.END_CONVERSATION}/${conversationId}`);
  }

  getStream() {
    return this.stream;
  }

  pauseRecording() {
    this.isPaused = true;
    // Clear any existing timeout
    if (this.pauseTimeout) {
      clearTimeout(this.pauseTimeout);
    }
    // Set new timeout
    this.pauseTimeout = setTimeout(async () => {
      if (this.isPaused) {
        await this.stopRecording();
        // Update UI state
        store.dispatch(setIsRecording(false));
      }
    }, this.WEBSOCKET_TIMEOUT);
  }

  resumeRecording() {
    this.isPaused = false;
    // Clear timeout when recording resumes
    if (this.pauseTimeout) {
      clearTimeout(this.pauseTimeout);
      this.pauseTimeout = null;
    }
  }

  getIsPaused() {
    return this.isPaused;
  }
}

export const audioService = new AudioService();