import { apiClient, API_ENDPOINTS } from "../../api/apiConfig";

export const fetchDateRange = (filters) => {
    const now = new Date();
    switch (filters.dateRange) {
        case "today":
            return {
                start: new Date(now.setHours(0, 0, 0, 0)),
                end: new Date(now.setHours(23, 59, 59, 999)),
            };
        case "yesterday":
            const yesterday = new Date(now);
            yesterday.setDate(yesterday.getDate() - 1);
            return {
                start: new Date(yesterday.setHours(0, 0, 0, 0)),
                end: new Date(yesterday.setHours(23, 59, 59, 999)),
            };
        case "lastWeek":
            const lastWeek = new Date(now);
            lastWeek.setDate(lastWeek.getDate() - 7);
            return {
                start: lastWeek,
                end: now,
            };
        case "lastMonth":
            const lastMonth = new Date(now);
            lastMonth.setMonth(lastMonth.getMonth() - 1);
            return {
                start: lastMonth,
                end: now,
            };
        default:
            return null;
    }
};

export const fetchSummarizedNotes = async (setLoadingSummaries, setSummarizedNotes) => {
    setLoadingSummaries(true);
    try {
        const response = await apiClient.get(`${API_ENDPOINTS.GET_CUSTOM_SUMMARIES}`);
        if (response.custom_summaries) {
            setSummarizedNotes(response.custom_summaries);
        }
    } catch (error) {
        console.error("Failed to fetch summarized notes:", error);
    } finally {
        setLoadingSummaries(false);
    }
};

export const fetchPredefinedPrompts = async (notes, selectedNotes, setLoadingPrompts, setPredefinedPrompts) => {
    setLoadingPrompts(true);
    try {
        const selectedRecords = notes?.records?.filter((note) => selectedNotes.includes(note.id)) || [];
        const recordType = [...new Set(selectedRecords.map((note) => note.record_type))];
        const category = [...new Set(selectedRecords.map((note) => note.category))];

        const queryParams = new URLSearchParams({
            record_type: recordType.join(","),
            category: category.join(","),
        }).toString();

        const response = await apiClient.get(`${API_ENDPOINTS.GET_CUSTOM_PROMPTS}?${queryParams}`);

        const formattedPrompts = response?.prompts?.map((prompt) => ({
            id: prompt.name,
            title: prompt.name
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" "),
            systemPrompt: prompt.prompt[0]?.content || "",
        }));

        setPredefinedPrompts(formattedPrompts);
    } catch (error) {
        console.error("Failed to fetch predefined prompts:", error);
    } finally {
        setLoadingPrompts(false);
    }
};

export const fetchRecordDetails = async (notes, recordId, setLoadingRecordDetails, setSelectedRecordDetails) => {
    setLoadingRecordDetails(true);
    try {
        const record = notes?.records?.find((note) => note.id === recordId);
        if (record) {
            setSelectedRecordDetails(record);
        }
    } catch (error) {
        console.error("Failed to fetch record details:", error);
    } finally {
        setLoadingRecordDetails(false);
    }
};

export const fetchCategoriesByRecordType = (notes) => {
    if (!notes?.records) return {};

    const recordTypeMap = {
        personal_note: 'personal',
        child_note: 'child',
        home_note: 'home'
    };

    return notes.records.reduce((acc, note) => {
        const recordType = recordTypeMap[note?.record_type];
        if (!acc[recordType]) {
            acc[recordType] = new Set();
        }
        if (note?.category) {
            acc[recordType].add(note.category);
        }
        return acc;
    }, {});
};

export const getCategoriesByRecordType = (notes) => {
    return Object.entries(fetchCategoriesByRecordType(notes)).reduce(
        (acc, [recordType, categoriesSet]) => {
            acc[recordType] = Array.from(categoriesSet);
            return acc;
        },
        {}
    );
};
