import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiClient, API_ENDPOINTS } from "../../api/apiConfig";

export const fetchNotes = createAsyncThunk("notes/fetchNotes", async () => {
  const response = await apiClient.get(API_ENDPOINTS.GET_RECORDS);
  return response;
});

export const syncPrompts = createAsyncThunk("notes/syncPrompts", async () => {
  const response = await apiClient.get(API_ENDPOINTS.SYNC_PROMPTS);
  return response;
});


const initialState = {
  notes: [],
  isNotesLoading: false,
  isPromptsLoading: false,
  error: null,
  currentRecording: null,
};

const notesSlice = createSlice({
  name: 'notes',
  initialState,
  reducers: {
    setCurrentRecording: (state, action) => {
      state.currentRecording = action.payload;
    },
    clearCurrentRecording: (state) => {
      state.currentRecording = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchNotes.pending, (state) => {
        state.isNotesLoading = true;
      })
      .addCase(fetchNotes.fulfilled, (state, action) => {
        state.isNotesLoading = false;
        state.notes = action.payload;
      })
      .addCase(fetchNotes.rejected, (state, action) => {
        state.isNotesLoading = false;
        state.error = action.error.message;
      })
      .addCase(syncPrompts.pending, (state) => {
        state.isPromptsLoading = true;
      })
      .addCase(syncPrompts.fulfilled, (state) => {
        state.isPromptsLoading = false;
      })
      .addCase(syncPrompts.rejected, (state, action) => {
        state.isPromptsLoading = false;
        state.error = action.error.message;
      });
  },
});

export const { setCurrentRecording, clearCurrentRecording } =
  notesSlice.actions;
export default notesSlice.reducer;
