import React, { createContext, useContext, useState, useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
  const {
    isAuthenticated,
    loginWithRedirect,
    logout,
    user,
    isLoading,
    getAccessTokenSilently,
    getIdTokenClaims,
  } = useAuth0();

  const [authToken, setAuthToken] = useState(() => sessionStorage.getItem('auth_token'));
  const [idToken, setIdToken] = useState(() => sessionStorage.getItem('id_token'));
  const [isSessionAuthenticated, setIsSessionAuthenticated] = useState(
    () => !!sessionStorage.getItem('auth_token')
  );

  useEffect(() => {
    const getToken = async () => {
      try {
        if (isAuthenticated) {
          const token = await getAccessTokenSilently();
          setAuthToken(token);
          setIsSessionAuthenticated(true);
          sessionStorage.setItem('auth_token', token);

          const idTokenClaims = await getIdTokenClaims();
          const idToken = idTokenClaims.__raw;
          setIdToken(idToken);
          sessionStorage.setItem('id_token', idToken);
        }
      } catch (error) {
        console.error('Error getting token:', error);
        setIsSessionAuthenticated(false);
      }
    };

    getToken();
  }, [isAuthenticated, getAccessTokenSilently, getIdTokenClaims]);

  const contextValue = {
    isAuthenticated: isAuthenticated || isSessionAuthenticated,
    authToken,
    idToken,
    user,
    isLoading,
    loginWithRedirect,
    logout: () => {
      sessionStorage.removeItem('auth_token');
      sessionStorage.removeItem('id_token');
      setIsSessionAuthenticated(false);
      logout({ returnTo: window.location.origin });
    },
  };

  return (
    <AuthContext.Provider value={contextValue}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error('useAuth must be used within an AuthProvider');
  }
  return context;
}; 