const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const API_ENDPOINTS = {
  //Notes APIs
  START_CONVERSATION: `${API_BASE_URL}/notes/record`,
  END_CONVERSATION: `${API_BASE_URL}/notes/end`,
  REGENERATE_DEFAULT_SUMMARY: (recordId) => `${API_BASE_URL}/notes/regenerate/${recordId}`,
  START_TEXT_CONVERSATION: `${API_BASE_URL}/notes/record/text`,


  //Children APIs
  CREATE_CHILD: `${API_BASE_URL}/children/create`,
  GET_CHILDREN: `${API_BASE_URL}/children`,

  //Homes APIs(not used)
  CREATE_HOME: `${API_BASE_URL}/homes/create`,
  GET_HOMES: `${API_BASE_URL}/homes`,

  // Highlights APIs
  GET_HIGHLIGHTS: `${API_BASE_URL}/highlights`,
  REGENERATE_HIGHLIGHTS: `${API_BASE_URL}/highlights/regenerate`,

  // Regenerate APIs
  GET_OBSERVATIONS_SUMMARY: (childName) =>
    `${API_BASE_URL}/observation/summary?child_name=${childName}`,
  REGENERATE_OBSERVATIONS_SUMMARY: `${API_BASE_URL}/observation/regenerate`,

  //Records APIs
  GET_RECORDING: (recordId) => `${API_BASE_URL}/recordings/${recordId}`,
  GET_RECORDS: `${API_BASE_URL}/records`,
  UPDATE_RECORD: (recordId) => `${API_BASE_URL}/record/${recordId}`,
  DELETE_RECORD: `${API_BASE_URL}/record`,

  //Custom Summaries APIs
  SUMMARIZE_NOTES: `${API_BASE_URL}/notes/summarise`, //not used
  GET_CUSTOM_SUMMARIES: `${API_BASE_URL}/custom_summaries`, //not used
  UPDATE_CUSTOM_SUMMARY: (summaryId) => `${API_BASE_URL}/custom_summary/${summaryId}`, //not used
  DELETE_CUSTOM_SUMMARY: `${API_BASE_URL}/custom_summary`, //not used

  //Category APIs
  CREATE_CATEGORY: `${API_BASE_URL}/category`,
  GET_CATEGORIES: (recordTypeName) => `${API_BASE_URL}/category/${recordTypeName}`,
  UPDATE_CATEGORY: (category_id) => `${API_BASE_URL}/category/${category_id}`,
  DELETE_CATEGORY: (category_id) => `${API_BASE_URL}/category/${category_id}`,

  //Sync Prompts APIs
  SYNC_PROMPTS: `${API_BASE_URL}/prompts/sync`,

  //Backend Prompts APIs
  CREATE_BACKEND_PROMPTS: `${API_BASE_URL}/backend_prompt`,
  GET_BACKEND_PROMPTS: `${API_BASE_URL}/backend_prompt`,
  UPDATE_BACKEND_PROMPTS: `${API_BASE_URL}/backend_prompt`,

  //Custom Prompts APIs
  CREATE_CUSTOM_PROMPTS: `${API_BASE_URL}/custom_prompts`,
  GET_CUSTOM_PROMPTS: `${API_BASE_URL}/custom_prompts`,
  UPDATE_CUSTOM_PROMPTS: `${API_BASE_URL}/custom_prompts`,

  //Auth0 APIs
  GET_MGMT_TOKEN: 'https://echo-elyndra.uk.auth0.com/oauth/token', //not used
  GET_ROLES: 'https://echo-elyndra.uk.auth0.com/api/v2/roles',
  GET_USER_ROLES_BY_ORG_ID: (orgId, userId) => `https://echo-elyndra.uk.auth0.com/api/v2/organizations/${orgId}/members/${userId}/roles`,
  GET_ORGANIZATION_NAME: (orgId) => `https://echo-elyndra.uk.auth0.com/api/v2/organizations/${orgId}`,
  GET_PERMISSION_BY_ROLE_ID: (roleId) => `https://echo-elyndra.uk.auth0.com/api/v2/roles/${roleId}/permissions`,
};

export const handleResponse = async (response) => {
  if (!response.ok) {
    const data = await response.json();
    const error = new Error('Something went wrong');
    error.originalError = data;
    error.response = { data };
    throw error;
  }
  return response.json();
};

export const apiClient = {
  get: async (url) => {
    const token = sessionStorage.getItem('id_token');
    const response = await fetch(url, {
      headers: {
        'Authorization': `Bearer ${token}`
      }
    });
    return handleResponse(response);
  },

  post: async (url, data) => {
    const token = sessionStorage.getItem('id_token');
    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token} `
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  },

  put: async (url, data) => {
    const token = sessionStorage.getItem('id_token');
    const response = await fetch(url, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        'Authorization': `Bearer ${token} `
      },
      body: JSON.stringify(data),
    });
    return handleResponse(response);
  },

  delete: async (url, options = {}) => {
    const token = sessionStorage.getItem('id_token');
    const response = await fetch(url, {
      method: "DELETE",
      headers: {
        'Authorization': `Bearer ${token} `,
        'Content-Type': 'application/json'
      },
      ...(options.data && { body: JSON.stringify(options.data) })
    });
    return handleResponse(response);
  },
};

export const fetchAudioUrl = async (recordId) => {
  try {
    const token = sessionStorage.getItem('id_token');
    const response = await fetch(API_ENDPOINTS.GET_RECORDING(recordId), {
      headers: {
        'Authorization': `Bearer ${token} `
      }
    });
    const blob = await response.blob();
    const url = URL.createObjectURL(blob);
    return url;
  } catch (error) {
    console.error("Failed to fetch audio URL:", error);
  }
};