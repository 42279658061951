import React from 'react';
import { ClockIcon } from '@heroicons/react/24/outline';
import { SettingsSection } from '../../components/Settings';

const SessionSection = () => {
    const sessionTimeout = process.env.REACT_APP_SESSION_TIMEOUT_MINUTES;
    const warningTimeout = process.env.REACT_APP_WARNING_BEFORE_TIMEOUT_SECONDS;

    const formatSessionTimeout = (minutes) => {
        if (minutes >= 60) {
            const hours = Math.floor(minutes / 60);
            const remainingMinutes = minutes % 60;
            return `${hours} ${hours === 1 ? 'hour' : 'hours'}${remainingMinutes > 0 ? ` and ${remainingMinutes} ${remainingMinutes === 1 ? 'minute' : 'minutes'}` : ''}`;
        }
        return `${minutes} ${minutes === 1 ? 'minute' : 'minutes'}`;
    };

    return (
        <SettingsSection title="Session Settings" icon={ClockIcon}>
            <div>
                <div className="mb-4">
                    <h4 className="text-sm font-medium text-gray-700">Session Timeout</h4>
                    <p className="mt-1 text-sm text-gray-500">
                        Your session will automatically end after {formatSessionTimeout(sessionTimeout)} of inactivity
                    </p>
                </div>
                <div>
                    <h4 className="text-sm font-medium text-gray-700">Warning Time</h4>
                    <p className="mt-1 text-sm text-gray-500">
                        You will receive a warning {warningTimeout} seconds before your session expires
                    </p>
                </div>
            </div>
        </SettingsSection>
    );
};

export default SessionSection; 