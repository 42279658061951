import React, { useState, useEffect } from 'react';
import { FolderIcon, PlusCircleIcon, XMarkIcon } from '@heroicons/react/24/outline';
import { SettingsSection } from '../../components/Settings';
import { apiClient, API_ENDPOINTS } from '../../api/apiConfig';
import toast from 'react-hot-toast';
import { Toaster } from 'react-hot-toast';
import { TOAST_OPTIONS } from '../../utils/constants';
import { createCategoryPermission, editCategoryPermission, deleteCategoryPermission } from '../../utils/permissions';
const formatCategoryName = (categoryName) => {
    return categoryName
        .split('_')
        .map(word => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ');
};

const CategorySection = () => {
    const [newCategory, setNewCategory] = useState('');
    const [selectedRecordType, setSelectedRecordType] = useState('');
    const [isAddingCategory, setIsAddingCategory] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isFetchingCategories, setIsFetchingCategories] = useState(true);
    const [error, setError] = useState(null);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [editedCategoryName, setEditedCategoryName] = useState('');
    const [isDeleting, setIsDeleting] = useState(false);
    const [isSaving, setIsSaving] = useState(false);

    // Updated record types structure with empty initial categories
    const [recordTypes, setRecordTypes] = useState([
        {
            id: 'personal_note',
            name: 'Personal Note',
            categories: []
        },
        {
            id: 'home_note',
            name: 'Home Note',
            categories: []
        },
        {
            id: 'child_note',
            name: 'Child Note',
            categories: []
        }
    ]);

    // Extract fetchCategories to a separate function outside useEffect
    const fetchCategories = async () => {
        setIsFetchingCategories(true);
        try {
            const categoryPromises = recordTypes.map(type =>
                apiClient.get(API_ENDPOINTS.GET_CATEGORIES(type.id))
            );

            const responses = await Promise.all(categoryPromises);

            setRecordTypes(prevTypes =>
                prevTypes.map((type, index) => ({
                    ...type,
                    categories: responses[index]?.categories?.length > 0
                        ? responses[index].categories.map(cat => ({
                            name: formatCategoryName(cat.category_name),
                            id: cat.id
                        }))
                        : []
                }))
            );
        } catch (error) {
            console.error('Error fetching categories:', error);
            setError('Failed to fetch categories');
        } finally {
            setIsFetchingCategories(false);
        }
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleAddCategory = async (recordTypeId) => {
        setError(null);
        setSelectedRecordType(recordTypeId);
        setIsAddingCategory(true);
    };

    const handleSubmitCategory = async (e) => {
        e.preventDefault();
        if (!newCategory.trim()) return;

        setIsLoading(true);
        setError(null);

        try {
            const formattedCategoryName = newCategory.trim().toLowerCase().replace(/\s+/g, '_');

            await apiClient.post(API_ENDPOINTS.CREATE_CATEGORY, {
                record_type_name: selectedRecordType,
                category_name: formattedCategoryName
            });

            // Reset form
            setNewCategory('');
            setIsAddingCategory(false);

            // Fetch updated categories
            await fetchCategories();

        } catch (error) {
            setError(error.message || 'Failed to create category');
            console.error('Error adding category:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleCategoryClick = (category, recordTypeId) => {
        setSelectedCategory({
            name: category.name,
            recordTypeId,
            categoryId: category.id
        });
        setIsModalOpen(true);
    };

    const handleDeleteCategory = async () => {
        setIsDeleting(true);
        try {
            await apiClient.delete(API_ENDPOINTS.DELETE_CATEGORY(selectedCategory.categoryId));
            await fetchCategories();
            setIsModalOpen(false);
            setSelectedCategory(null);
        } catch (error) {
            const errorDetail = error?.originalError?.detail;
            if (errorDetail === "Category is in use by a record") {
                toast.error("Cannot delete category because it is being used by one or more records");
            } else if (errorDetail === "Category is in use by a custom prompt") {
                toast.error("Cannot delete category because it is being used by one or more custom prompts");
            } else if (errorDetail === "Category is in use by a backend prompt") {
                toast.error("Cannot delete category because it is being used by one or more backend prompts");
            } else {
                toast.error(error?.message);
                console.error('Error deleting category:', error);
            }
        } finally {
            setIsDeleting(false);
        }
    };

    const handleEditCategory = () => {
        setEditedCategoryName(selectedCategory.name);
        setIsEditing(true);
    };

    const handleSaveEdit = async () => {
        if (!editedCategoryName.trim()) return;

        setIsSaving(true);
        try {
            const formattedCategoryName = editedCategoryName.trim().toLowerCase().replace(/\s+/g, '_');

            await apiClient.put(API_ENDPOINTS.UPDATE_CATEGORY(selectedCategory.categoryId), {
                category_name: formattedCategoryName
            });

            await fetchCategories();
            setIsEditing(false);
            setIsModalOpen(false);
            setSelectedCategory(null);
        } catch (error) {
            setError(error.message || 'Failed to update category');
            console.error('Error updating category:', error);
        } finally {
            setIsSaving(false);
        }
    };

    return (
        <SettingsSection title="Manage Categories" icon={FolderIcon}>
            <Toaster
                position="top-center"
                toastOptions={TOAST_OPTIONS}
            />
            <div className="space-y-6">
                {error && (
                    <div className="rounded-md bg-red-50 p-4 mb-4">
                        <div className="flex">
                            <div className="text-sm text-red-700">
                                {error}
                            </div>
                        </div>
                    </div>
                )}

                {/* Modal Overlay */}
                {isModalOpen && (
                    <div className="fixed inset-0 bg-black bg-opacity-50 z-40 flex items-center justify-center">
                        <div className="bg-white rounded-lg p-6 max-w-sm w-full relative">
                            <button
                                onClick={() => {
                                    setIsModalOpen(false);
                                    setIsEditing(false);
                                }}
                                className="absolute top-4 right-4 text-gray-400 hover:text-gray-500"
                            >
                                <XMarkIcon className="h-6 w-6" />
                            </button>

                            <div className="space-y-4">
                                {/* Record Type Name */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        For Record Type - <span className="font-bold">{recordTypes.find(rt => rt.id === selectedCategory?.recordTypeId)?.name}</span>
                                    </label>
                                </div>

                                {/* Category Name */}
                                <div>
                                    <label className="block text-sm font-medium text-gray-700">
                                        Category Name
                                    </label>
                                    {isEditing ? (
                                        <input
                                            type="text"
                                            value={editedCategoryName}
                                            onChange={(e) => setEditedCategoryName(e.target.value)}
                                            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm"
                                        />
                                    ) : (
                                        <div className="mt-1 text-lg font-bold text-gray-900">
                                            {selectedCategory?.name}
                                        </div>
                                    )}
                                </div>

                                {/* Action Buttons */}
                                <div className="flex justify-end space-x-3 mt-6">
                                    {isEditing ? (
                                        <>
                                            <button
                                                onClick={handleSaveEdit}
                                                disabled={isSaving}
                                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-primary-600 hover:bg-primary-700 rounded-md"
                                            >
                                                {isSaving ? (
                                                    <>
                                                        <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                            <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                            <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                        </svg>
                                                        Saving...
                                                    </>
                                                ) : 'Save'}
                                            </button>
                                            <button
                                                onClick={() => setIsEditing(false)}
                                                disabled={isSaving}
                                                className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                                            >
                                                Cancel
                                            </button>
                                        </>
                                    ) : (
                                        <>
                                            {editCategoryPermission() && selectedCategory?.name !== "Observation" && (
                                                <button
                                                    onClick={handleEditCategory}
                                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50"
                                                >
                                                    Edit
                                                </button>
                                            )}
                                            {deleteCategoryPermission() && selectedCategory?.name !== "Observation" && (
                                                <button
                                                    onClick={handleDeleteCategory}
                                                    disabled={isDeleting}
                                                    className="inline-flex justify-center px-4 py-2 text-sm font-medium text-white bg-red-600 hover:bg-red-700 rounded-md"
                                                >
                                                    {isDeleting ? (
                                                        <>
                                                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                                            </svg>
                                                            Deleting...
                                                        </>
                                                    ) : 'Delete'}
                                                </button>
                                            )}
                                        </>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                )}

                {recordTypes.map((recordType) => (
                    <div
                        key={recordType.id}
                        className="bg-white p-4 rounded-lg border border-gray-200 shadow-sm"
                    >
                        <div className="flex justify-between items-center mb-3">
                            <h3 className="text-lg font-medium text-gray-900">
                                {recordType.name}
                            </h3>
                            {createCategoryPermission() && !isFetchingCategories && (
                                <button
                                    onClick={() => handleAddCategory(recordType.id)}
                                    className="inline-flex items-center px-3 py-1 text-sm font-medium text-primary-600 hover:text-primary-700"
                                >
                                    <PlusCircleIcon className="h-5 w-5 mr-1" />
                                    Add Category
                                </button>
                            )}
                        </div>

                        <div className="flex flex-wrap gap-2">
                            {isFetchingCategories ? (
                                <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-600">
                                    Loading categories...
                                </span>
                            ) : (
                                recordType.categories.length > 0 ? (
                                    recordType.categories.map((category) => (
                                        <span
                                            key={category.id}
                                            onClick={() => handleCategoryClick(category, recordType.id)}
                                            className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-800 hover:bg-gray-200 cursor-pointer transition-colors duration-200"
                                        >
                                            {category.name}
                                        </span>
                                    ))
                                ) : (
                                    <span className="inline-flex items-center px-3 py-1 rounded-full text-sm font-medium bg-gray-100 text-gray-500">
                                        Not Available
                                    </span>
                                )
                            )}
                        </div>

                        {isAddingCategory && selectedRecordType === recordType.id && (
                            <form
                                onSubmit={handleSubmitCategory}
                                className="mt-3 flex items-center gap-2"
                            >
                                <input
                                    type="text"
                                    value={newCategory}
                                    onChange={(e) => setNewCategory(e.target.value)}
                                    placeholder="Enter new category name"
                                    className="flex-1 rounded-md border-gray-300 shadow-sm focus:border-primary-500 focus:ring-primary-500 sm:text-sm px-3 py-2"
                                    disabled={isLoading}
                                />
                                <button
                                    type="submit"
                                    className={`inline-flex items-center px-3 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white 
                                        ${isLoading
                                            ? 'bg-primary-400 cursor-not-allowed'
                                            : 'bg-primary-600 hover:bg-primary-700'
                                        }`}
                                    disabled={isLoading}
                                >
                                    {isLoading ? (
                                        <>
                                            <svg className="animate-spin -ml-1 mr-2 h-4 w-4 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                                                <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                                                <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                                            </svg>
                                            Adding...
                                        </>
                                    ) : (
                                        'Add'
                                    )}
                                </button>
                                <button
                                    type="button"
                                    onClick={() => {
                                        setIsAddingCategory(false);
                                        setNewCategory('');
                                        setError(null);
                                    }}
                                    className="inline-flex items-center px-3 py-2 border border-gray-300 text-sm font-medium rounded-md shadow-sm text-gray-700 bg-white hover:bg-gray-50"
                                    disabled={isLoading}
                                >
                                    Cancel
                                </button>
                            </form>
                        )}
                    </div>
                ))}
            </div>
        </SettingsSection>
    );
};

export default CategorySection; 