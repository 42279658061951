import React from 'react';
import ExpandIcon from '../NotesView/ExpandIcon';

function DailyTimeline({ events, expandedEvents, toggleEventExpansion }) {
    return (
        <div className="mt-8">
            <h3 className="text-xl font-semibold mb-4">Daily Timeline</h3>
            <div className="mt-2 overflow-hidden bg-white shadow sm:rounded-md">
                <div className="px-4 py-5 sm:p-6">
                    <div className="prose max-w-none">
                        {events?.map((event, index) => (
                            <div key={index} className="mb-6 relative pl-4 border-l-4"
                                style={{
                                    borderColor: event.status === 'green'
                                        ? '#22c55e'
                                        : event.status === 'amber'
                                            ? '#f59e0b'
                                            : '#ef4444'
                                }}>
                                <div
                                    className="flex items-center gap-3 mb-2 cursor-pointer"
                                    onClick={() => toggleEventExpansion(index)}
                                >
                                    <span className="text-sm text-gray-500">{event.time}</span>
                                    <h3 className="text-lg font-semibold text-gray-900 flex items-center">
                                        {event.summary}
                                        <span className="ml-2">
                                            <ExpandIcon isExpanded={expandedEvents.has(index)} />
                                        </span>
                                    </h3>
                                </div>
                                {expandedEvents.has(index) && (
                                    <div className="transition-all duration-200 ease-in-out">
                                        <p className="text-gray-700">{event.details}</p>
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
        </div>
    );
}

export default DailyTimeline; 