import React, { useEffect, useState, useCallback } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleSidebar } from "../store/slices/uiSlice";
import { apiClient, API_ENDPOINTS } from "../api/apiConfig";
import {
  HomeIcon,
  MicrophoneIcon,
  DocumentTextIcon,
  Bars3Icon as MenuIcon,
  XMarkIcon,
  ArrowRightOnRectangleIcon,
  UserCircleIcon,
  ArrowsRightLeftIcon,
  Cog6ToothIcon,

} from "@heroicons/react/24/outline";
import { useAuth0 } from "@auth0/auth0-react";
import { Dialog as HeadlessDialog, Menu } from '@headlessui/react';
import SelectChildPopover from './Records/SelectChildPopover';

function Layout({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const isSidebarOpen = useSelector((state) => state.ui.isSidebarOpen);
  const { loginWithRedirect, logout, user, isAuthenticated } = useAuth0();
  const [currentUser, setCurrentUser] = useState(null);
  const [selectedChild, setSelectedChild] = useState(null);
  const [showChildPopover, setShowChildPopover] = useState(false);

  // Get timeout values from env with defaults
  const sessionTimeoutMinutes = parseInt(process.env.REACT_APP_SESSION_TIMEOUT_MINUTES || '5');
  const warningBeforeTimeoutSeconds = parseInt(process.env.REACT_APP_WARNING_BEFORE_TIMEOUT_SECONDS || '60');

  // Convert to milliseconds
  const sessionTimeoutMs = sessionTimeoutMinutes * 60 * 1000;
  const warningTimeoutMs = sessionTimeoutMs - (warningBeforeTimeoutSeconds * 1000);

  const [showTimeoutWarning, setShowTimeoutWarning] = useState(false);
  const [countdown, setCountdown] = useState(warningBeforeTimeoutSeconds);

  const handleSignOut = useCallback(async () => {
    try {
      setShowTimeoutWarning(false);
      await new Promise(resolve => setTimeout(resolve, 100));

      // Clear session storage
      sessionStorage.clear();

      await logout({
        returnTo: window.location.origin
      });

    } catch (error) {
      console.error('Logout failed with error:', error);
    }
  }, [logout]);

  useEffect(() => {
    let timeoutId;
    let warningTimeoutId;
    let countdownInterval;

    const resetTimeout = () => {
      if (!showTimeoutWarning) {
        if (timeoutId) clearTimeout(timeoutId);
        if (warningTimeoutId) clearTimeout(warningTimeoutId);

        warningTimeoutId = setTimeout(() => {
          setShowTimeoutWarning(true);
          setCountdown(warningBeforeTimeoutSeconds);
        }, warningTimeoutMs);

        timeoutId = setTimeout(handleSignOut, sessionTimeoutMs);
      }
    };

    if (showTimeoutWarning) {
      countdownInterval = setInterval(() => {
        setCountdown(prev => {
          if (prev <= 1) {
            clearInterval(countdownInterval);
            handleSignOut();
            return 0;
          }
          return prev - 1;
        });
      }, 1000);
    }

    const events = ['mousedown', 'keydown', 'scroll', 'touchstart'];
    events.forEach(event => {
      window.addEventListener(event, resetTimeout);
    });

    resetTimeout();

    return () => {
      if (timeoutId) clearTimeout(timeoutId);
      if (warningTimeoutId) clearTimeout(warningTimeoutId);
      if (countdownInterval) clearInterval(countdownInterval);
      events.forEach(event => {
        window.removeEventListener(event, resetTimeout);
      });
    };
  }, [sessionTimeoutMs, warningTimeoutMs, warningBeforeTimeoutSeconds, showTimeoutWarning, handleSignOut]);

  useEffect(() => {
    const fetchUserRoles = async () => {
      if (isAuthenticated) {
        try {
          const token = process.env.REACT_APP_AUTH0_TOKEN;
          const response = await fetch(API_ENDPOINTS.GET_USER_ROLES_BY_ORG_ID(user.org_id, user.sub), {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          if (!response.ok) {
            throw new Error('Failed to fetch user roles');
          }
          const data = await response.json();
          // Store the role in sessionStorage
          let role = null;
          if (data && data.length > 0) {
            // First check for Admin role
            role = data.find(r => r.name === 'Admin') || data[0];
            sessionStorage.setItem('userRoleId', role.id);
            sessionStorage.setItem('userRole', role.name);
          } else {
            const roles = await fetch(API_ENDPOINTS.GET_ROLES, {
              headers: {
                'Authorization': `Bearer ${token}`
              }
            });
            const rolesData = await roles.json();
            role = rolesData.find(role => role.name === 'User');
            sessionStorage.setItem('userRoleId', role.id);
            sessionStorage.setItem('userRole', role.name);
          }

          const orgResponse = await fetch(API_ENDPOINTS.GET_ORGANIZATION_NAME(user.org_id), {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });
          if (!orgResponse.ok) {
            throw new Error('Failed to fetch organization name');
          }

          const orgData = await orgResponse.json();
          // Store the organization name in sessionStorage
          sessionStorage.setItem('organizationId', orgData.id);
          sessionStorage.setItem('organizationName', orgData.display_name);
          const permissions = await fetch(API_ENDPOINTS.GET_PERMISSION_BY_ROLE_ID(sessionStorage.getItem('userRoleId')), {
            headers: {
              'Authorization': `Bearer ${token}`
            }
          });

          const permissionsData = await permissions.json();
          if (permissionsData) {
            sessionStorage.setItem('permissions', JSON.stringify(permissionsData));
          }

          setCurrentUser({
            ...user,
            role,
          });
        } catch (error) {
          console.error('Failed to fetch user roles:', error);
        }
      }
    };

    fetchUserRoles();
  }, [isAuthenticated, user]);

  const handleContinue = () => {
    setShowTimeoutWarning(false);
    window.dispatchEvent(new Event('mousedown'));
  };

  const navItems = [
    { path: "/home", icon: HomeIcon, label: "Home" },
    { path: "/record-page", icon: MicrophoneIcon, label: "Record" },
    { path: "/notes", icon: DocumentTextIcon, label: "Notes" },
    { path: "/settings", icon: Cog6ToothIcon, label: "Settings" },
  ];

  const UserProfileMenu = () => (
    <Menu as="div" className="relative">
      <Menu.Button className="flex items-center w-full px-4 py-3 text-sm font-medium text-left text-gray-600 hover:bg-gray-50 hover:text-gray-900">
        <UserCircleIcon className="mr-3 h-5 w-5" />
        <div className="flex-1">
          <div className="font-medium">{currentUser?.name || 'User'}</div>
          <div className="text-xs text-gray-500">{currentUser?.email}</div>
          <div className="text-xs text-gray-500">
            <span className="font-bold">Home Name: </span>
            {sessionStorage.getItem('organizationName')}
          </div>
          {currentUser?.role && (
            <div className="text-xs text-gray-500">
              <span className="font-bold">Role: </span>
              {currentUser?.role?.name}
            </div>
          )}
        </div>
      </Menu.Button>
      <Menu.Items className="absolute left-0 right-0 mt-1 mx-2 origin-top-right bg-white divide-y divide-gray-100 rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
        <div className="py-1">
          <Menu.Item>
            {({ active }) => (
              <button
                onClick={handleSignOut}
                className={`${active ? 'bg-gray-100' : ''
                  } flex w-full items-center px-4 py-2 text-sm text-gray-700`}
              >
                <ArrowRightOnRectangleIcon className="mr-3 h-5 w-5" />
                Sign Out
              </button>
            )}
          </Menu.Item>
        </div>
      </Menu.Items>
    </Menu>
  );

  const TimeoutWarningDialog = () => {
    return (
      <HeadlessDialog
        open={showTimeoutWarning}
        onClose={handleContinue}
        className="relative z-50"
      >
        <div className="fixed inset-0 bg-black/30" aria-hidden="true" />

        <div className="fixed inset-0 flex items-center justify-center p-4">
          <HeadlessDialog.Panel className="relative bg-white rounded-lg max-w-md w-full p-6 shadow-xl">
            <HeadlessDialog.Title className="text-lg font-medium text-gray-900">
              Session Timeout Warning
            </HeadlessDialog.Title>

            <div className="mt-4">
              <p className="text-sm text-gray-500">
                Your session will expire in {countdown} seconds. Would you like to continue?
              </p>

              <div className="mt-4 h-2 bg-gray-200 rounded-full">
                <div
                  className="h-full bg-primary-600 rounded-full transition-all duration-1000"
                  style={{
                    width: `${(countdown / warningBeforeTimeoutSeconds) * 100}%`
                  }}
                />
              </div>
            </div>

            <div className="mt-6 flex justify-end space-x-3">
              <button
                type="button"
                onClick={async (e) => {
                  e.preventDefault();
                  await handleSignOut();
                }}
                className="px-4 py-2 text-sm font-medium text-gray-700 bg-gray-100 rounded-md hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-500"
              >
                Sign Out
              </button>
              <button
                type="button"
                onClick={handleContinue}
                className="px-4 py-2 text-sm font-medium text-white bg-primary-600 rounded-md hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-primary-500"
              >
                Continue Session
              </button>
            </div>
          </HeadlessDialog.Panel>
        </div>
      </HeadlessDialog>
    )
  };

  useEffect(() => {
    const checkAndSetChild = async () => {
      const savedChild = localStorage.getItem('selectedChild');
      if (savedChild) {
        try {
          await new Promise(resolve => setTimeout(resolve, 3000));
          const response = await apiClient.get(API_ENDPOINTS.GET_CHILDREN);
          const children = response.children || [];

          const parsedChild = JSON.parse(savedChild);
          const childExists = children.some(child =>
            child.id === parsedChild.id &&
            child.child_name === parsedChild.name
          );

          if (!childExists) {
            localStorage.removeItem('selectedChild');
            setSelectedChild(null);
            setShowChildPopover(true);
          } else {
            const currentChild = children.find(child =>
              child.id === parsedChild.id ||
              child.child_name === parsedChild.name
            );
            const updatedChild = {
              id: currentChild.id,
              name: currentChild.child_name,
              colour: currentChild.colour
            };
            localStorage.setItem('selectedChild', JSON.stringify(updatedChild));
            setSelectedChild(updatedChild);
          }
        } catch (error) {
          console.error('Error checking child existence:', error);
          setSelectedChild(JSON.parse(savedChild));
        }
      } else {
        setTimeout(() => {
          setShowChildPopover(true);
        }, 1000);
      }
    };

    if (location.pathname === '/home') {
      checkAndSetChild();
    } else {
      const savedChild = localStorage.getItem('selectedChild');
      if (savedChild) {
        setSelectedChild(JSON.parse(savedChild));
      }
    }
  }, [location.pathname]);

  const handleChildSelect = (child) => {
    setSelectedChild(child);
    setShowChildPopover(false);
    localStorage.setItem('selectedChild', JSON.stringify(child));
    // Dispatch a custom event to notify components of the change
    window.dispatchEvent(new Event('storage'));
  };

  return (
    <>
      <TimeoutWarningDialog />
      {showChildPopover && (
        <SelectChildPopover
          onClose={() => setShowChildPopover(false)}
          onSelectChild={handleChildSelect}
        />
      )}
      <div className="min-h-screen flex">
        {/* Mobile Header */}
        <div className="lg:hidden fixed top-0 left-0 right-0 bg-white shadow-sm z-30">
          <div className="px-4 py-2 flex items-center justify-between">
            <button
              onClick={() => dispatch(toggleSidebar())}
              className="p-2 rounded-md text-gray-500 hover:text-gray-600 focus:outline-none"
            >
              <MenuIcon className="h-6 w-6" />
            </button>
            <h1 className="text-xl font-semibold text-gray-800">Echo</h1>
            <div className="w-8" /> {/* Spacer for centering */}
          </div>
        </div>

        {isSidebarOpen && (
          <div
            className="fixed inset-0 bg-gray-600 bg-opacity-50 z-20 lg:hidden"
            onClick={() => dispatch(toggleSidebar())}
          />
        )}

        <div
          className={`fixed lg:sticky top-0 h-full w-64 bg-white transition-transform duration-200 ease-in-out transform ${isSidebarOpen ? "translate-x-0" : "-translate-x-full"
            } lg:translate-x-0 z-30`}
        >
          <div className="flex flex-col h-full">
            <div className="px-4 py-6 flex items-center justify-between">
              <h1 className="text-2xl font-bold text-gray-800">Echo</h1>
              <button
                onClick={() => dispatch(toggleSidebar())}
                className="lg:hidden p-2 rounded-md text-gray-500 hover:text-gray-600 focus:outline-none"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>

            <nav className="flex-1 px-2 py-4 space-y-1">
              <UserProfileMenu />
              <div className="my-3 border-t border-gray-200" />
              {navItems.map(({ path, icon: Icon, label }) => (
                <Link
                  key={path}
                  to={path}
                  className={`flex items-center px-4 py-2 text-sm font-medium rounded-md ${location.pathname === path
                    ? "bg-primary-100 text-primary-700"
                    : "text-gray-600 hover:bg-gray-50 hover:text-gray-900"
                    }`}
                >
                  <Icon className="mr-3 h-5 w-5" />
                  {label}
                </Link>
              ))}
            </nav>
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 min-w-0 bg-gray-100">
          <main className="max-w-7xl mx-auto py-6 px-4 sm:px-6 lg:px-8 mt-14 lg:mt-0 mb-20 lg:mb-0">
            {/* Update Selected Child Banner */}
            {selectedChild ? (
              <div className="mb-6">
                <div
                  className="p-2 rounded-lg flex justify-between items-center text-sm"
                  style={{
                    backgroundColor: `#${selectedChild.colour || 'FF5733'}20`,
                    borderLeft: `4px solid #${selectedChild.colour || 'FF5733'}`
                  }}
                >
                  <p className="text-gray-700 text-sm">Selected Child: {selectedChild.name}</p>
                  <button
                    onClick={() => setShowChildPopover(true)}
                    className="px-3 py-1 text-xs font-medium bg-white rounded-md border transition-colors"
                    style={{
                      color: `#${selectedChild.colour || 'FF5733'}`,
                      borderColor: `#${selectedChild.colour || 'FF5733'}`,
                      '&:hover': {
                        backgroundColor: `#${selectedChild.colour || 'FF5733'}10`
                      }
                    }}
                  >
                    Switch Child
                  </button>
                </div>
              </div>
            ) : (
              <div className="mb-6">
                <button
                  onClick={() => setShowChildPopover(true)}
                  className="w-full p-2 rounded-lg border-2 border-dashed border-gray-300 text-gray-500 hover:border-gray-400 hover:text-gray-600 transition-colors"
                >
                  Click here to select a child
                </button>
              </div>
            )}
            {children}
          </main>
        </div>

        {/* Mobile Navigation */}
        <div className="lg:hidden fixed bottom-0 left-0 right-0 bg-white border-t border-gray-200 z-30">
          <nav className="flex justify-around">
            {navItems.map(({ path, icon: Icon, label }) => (
              <Link
                key={path}
                to={path}
                className={`flex flex-col items-center py-2 px-3 text-sm ${location.pathname === path
                  ? "text-primary-600"
                  : "text-gray-600"
                  }`}
              >
                <Icon className="h-6 w-6" />
                <span className="mt-1">{label}</span>
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </>
  );
}

export default Layout;
