// Create a function to check admin status
const checkIsAdmin = () => sessionStorage.getItem('userRole') === 'Admin';

// Create a function to get current permissions
const getCurrentPermissions = () => sessionStorage.getItem('permissions');

// Permissions for Category Section
export const createCategoryPermission = () => getCurrentPermissions()?.includes('create:category') || checkIsAdmin();
export const editCategoryPermission = () => getCurrentPermissions()?.includes('update:category') || checkIsAdmin();
export const deleteCategoryPermission = () => getCurrentPermissions()?.includes('delete:category') || checkIsAdmin();

// Permissions for Child Section
export const createChildPermission = () => getCurrentPermissions()?.includes('create:child') || checkIsAdmin();

// Permissions for Record Section
export const editAINoteSummaryPermission = () => getCurrentPermissions()?.includes('update:summary') || checkIsAdmin();
export const deleteRecordPermission = () => getCurrentPermissions()?.includes('delete:record') || checkIsAdmin();
export const deleteThreadPermission = () => getCurrentPermissions()?.includes('delete:thread') || checkIsAdmin();

// Permissions for Prompt Section
export const createBackendPromptPermission = () => getCurrentPermissions()?.includes('create:backend_prompt') || checkIsAdmin();
export const editBackendPromptPermission = () => getCurrentPermissions()?.includes('update:backend_prompt') || checkIsAdmin();
export const createSmartPromptPermission = () => getCurrentPermissions()?.includes('create:smart_prompt') || checkIsAdmin();
export const editSmartPromptPermission = () => getCurrentPermissions()?.includes('update:smart_prompt') || checkIsAdmin();