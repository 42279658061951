import toast from 'react-hot-toast';
import { API_ENDPOINTS, apiClient } from '../../api/apiConfig';

export const fetchHighlights = async (selectedDate, selectedName) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.GET_HIGHLIGHTS, {
            date: selectedDate,
            child_name: selectedName,
        });

        return response;
    } catch (error) {
        const errorDetail = error?.originalError?.detail;
        if (errorDetail) {
            toast.error(errorDetail);
        }
        console.error('Error fetching highlights:', error);
        throw error;
    }
};

export const regenerateHighlights = async (selectedDate, selectedName) => {
    try {
        const response = await apiClient.post(API_ENDPOINTS.REGENERATE_HIGHLIGHTS, {
            date: selectedDate,
            child_name: selectedName,
        });

        return response;
    } catch (error) {
        const errorDetail = error?.originalError?.detail;
        if (errorDetail) {
            toast.error("No Records Found");
        }
        console.error('Error fetching highlights:', error);
        throw error;
    }
};